import { defineStore } from 'pinia';
import _ from 'lodash';

export interface Filters {
    selected: Options[];
}

export enum Options {
    contact_info = 'contact_info',
    experiences = 'experiences',
    top_skill = 'top_skill',
    resume = 'resume',
    inferences_map = 'inferences_map',
    evaluations = 'evaluations',
    sharings = 'sharings',
}

export const useTalentViewFilter = defineStore('talentViewFilter', {
    state: (): Filters => {
        return {
            selected: [
                Options.contact_info,
                Options.experiences,
                Options.top_skill,
                Options.resume,
                Options.inferences_map,
                Options.evaluations,
                Options.sharings,
            ],
        };
    },
    actions: {
        setFilter(filter: Options) {
            if (this.selected.includes(filter)) {
                _.remove(this.selected, (element) => element === filter);
            } else {
                this.selected.push(filter);
            }
        },
    },
});
