import {
    AcceptPrivacyDocument,
    AcceptTermConditionDocument,
    CurrentAppVisualization,
    DeleteMeDocument,
    Maybe,
    PrivacyLastDocument,
    ResendUserWelcomeEmailDocument,
    SetCurrentAppVisualizationDocument,
    TermConditionLastDocument,
    TermConditionType,
    UpdateUserDocument,
    User,
    UserConfirmDocument,
    UserDocument,
} from '../graphql/types/graphql';
import { Ref, ref, watch } from 'vue';

import _ from 'lodash';
import { getSurvey } from './survey';
import { getUserCompanies } from './companies';
import { initCustomerly } from '@/plugins/customerly';
import mutation from '@/graphql/mutation';
import query from '@/graphql/query';
import { useErrorStore } from '@/store/error';
import { useOnboardingStore } from '@/store/onboarding';
import { useUserStore } from '@/store/user';

export function getUser(callback: any = null, disableSyncOnboardingStore = false) {
    const userStore = useUserStore();
    const { result, onError } = query(UserDocument, {
        id: '',
    });
    watch(result, async (data) => {
        handleUser(data?.User as Maybe<User>, disableSyncOnboardingStore);
        if (callback) {
            await callback();
        }
    });
    onError((result) => {
        userStore.fetching = false;
        console.error(result.message);
    });
    //getSurvey();
}

function handleUser(user: Maybe<User>, disableSyncOnboardingStore = false) {
    const userStore = useUserStore();
    const onboardingStore = useOnboardingStore();
    userStore.setUser(user);
    if (!disableSyncOnboardingStore) {
        onboardingStore.syncUserData(user);
    }
    userStore.fetching = false;
    if (userStore.viewTypeUser !== null) {
        localStorage.setItem('userType', userStore.viewTypeUser ? 'user' : 'company');
    }
    //initCustomerly(user);
}

export function getProfilePercentage() {
    const user = useUserStore().user;
    const total = 6;
    let count = 0;

    if (user?.photo != null) {
        count++;
    }
    if (user?.biografy != null) {
        count++;
    }
    if (!_.isEmpty(user?.qualifications)) {
        count++;
    }
    if (!_.isEmpty(user?.skills)) {
        count++;
    }
    if (!_.isEmpty(user?.spokenLanguages)) {
        count++;
    }
    if (!_.isEmpty(user?.socials)) {
        count++;
    }

    return Math.round((count / total) * 100);
}

function handleAppVisualizzation(appVisualizzation: Maybe<CurrentAppVisualization>) {
    const userStore = useUserStore();
    if (userStore.user) {
        userStore.user.currentAppVisualization = appVisualizzation;
        if (userStore.viewTypeUser !== null) {
            localStorage.setItem('userType', userStore.viewTypeUser ? 'user' : 'company');
        }
    }
}

export function useUpdateUser() {
    const { mutate, loading, onDone, onError } = mutation(UpdateUserDocument);
    onError((result) => {
        console.error(result.message);
    });

    const result: Ref<Maybe<User>> = ref(null);

    onDone((response) => {
        getUser();
        result.value = response?.data?.updateUser as Maybe<User>;
    });
    return { mutate, loading, onDone, result, onError };
}

export function useChangeCurrentAppVisualizzation() {
    const { mutate, loading, onDone, onError } = mutation(
        SetCurrentAppVisualizationDocument
    );
    onError((result) => {
        console.error(result.message);
    });

    const result: Ref<Maybe<CurrentAppVisualization>> = ref(null);

    onDone((response) => {
        getUserCompanies();
        handleAppVisualizzation(
            response?.data?.setCurrentAppVisualization as Maybe<CurrentAppVisualization>
        );
        getUser();
        result.value = response?.data
            ?.setCurrentAppVisualization as Maybe<CurrentAppVisualization>;
    });
    return { mutate, loading, onDone, result };
}

export function useResendUserWelcomeEmail() {
    const { mutate, loading, onDone, onError } = mutation(ResendUserWelcomeEmailDocument);

    onError((result) => {
        console.error(result.message);
    });

    const result: Ref<Maybe<boolean>> = ref(null);

    onDone((response) => {
        result.value = response?.data?.resendUserWelcomeEmail as Maybe<boolean>;
    });

    return { mutate, loading, onDone, result };
}

export function useAcceptTermAndCondition(callback?: any) {
    const { mutate, loading, onDone, onError } = mutation(AcceptTermConditionDocument);
    onError((result) => {
        console.error(result.message);
    });

    const result: Ref<Maybe<User>> = ref(null);

    onDone((response) => {
        result.value = response?.data?.acceptTermCondition as Maybe<User>;
        getUser(callback);
    });
    return { mutate, loading, onDone, result };
}

export function useAcceptPrivacy() {
    const { mutate, loading, onDone, onError } = mutation(AcceptPrivacyDocument);
    onError((result) => {
        console.error(result.message);
    });

    const result: Ref<Maybe<User>> = ref(null);

    onDone((response) => {
        getUser();
        result.value = response?.data?.acceptPrivacy as Maybe<User>;
    });
    return { mutate, loading, onDone, result };
}

export function useUserConfirm() {
    const { mutate, loading, onDone, onError } = mutation(UserConfirmDocument);
    onError((result) => {
        console.error(result.message);
    });

    const result: Ref<Maybe<boolean>> = ref(null);

    onDone((response) => {
        getUser();
        result.value = response?.data?.userConfirm as boolean;
    });
    return { mutate, loading, onDone, result };
}

export function useGetPrivacy(language: string) {
    const { result, onError, loading } = query(PrivacyLastDocument, {
        language_id: language,
    });

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useGetTerms(language: string, type: TermConditionType) {
    const { result, onError, loading } = query(TermConditionLastDocument, {
        language_id: language,
        type,
    });

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useDeleteMe() {
    const { mutate, loading, onDone, onError } = mutation(DeleteMeDocument);

    onError((result) => {
        console.error(result.message);
    });

    const result: Ref<Maybe<boolean>> = ref(null);

    onDone((response) => {
        getUser();
        result.value = response?.data?.deleteMe as boolean;
    });
    return { mutate, loading, onDone, result };
}
