<template>
    <v-menu class="menu-custom">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn
                v-if="props.isTalent"
                :color="getButtonColor()"
                v-bind="activatorProps"
                elevation="0"
                rounded="pill"
                variant="outlined"
            >
                <v-icon :color="getTextIconColor()" class="mr-2">mdi-menu</v-icon>
                <v-avatar size="x-small">
                    <img
                        v-if="getImageProfile()"
                        style="height: 100%"
                        :src="getImageProfile()"
                    />
                    <v-icon v-else size="x-large" :color="getTextIconColor()">
                        mdi-account-circle
                    </v-icon>
                </v-avatar>
            </v-btn>
            <!-- <v-badge v-if="!props.isTalent" content="2" color="primary" class="ml-4 mr-4">
                <v-btn
                    v-bind="activatorProps"
                    :color="getButtonColor()"
                    elevation="0"
                    rounded="pill"
                    variant="outlined"
                    size="small"
                    class="font"
                    icon
                >
                    <v-avatar size="small">
                        <v-img
                            v-if="getImageProfile()"
                            style="height: 100%"
                            :src="getImageProfile()"
                        ></v-img>
                        <v-icon v-else :color="getTextIconColor()" size="large">
                            mdi-account
                        </v-icon>
                    </v-avatar>
                </v-btn>
            </v-badge> -->
            <v-btn
                v-bind="activatorProps"
                v-if="!props.isTalent"
                :color="getButtonColor()"
                elevation="0"
                rounded="pill"
                variant="outlined"
                size="small"
                class="font"
                icon
            >
                <v-avatar size="small">
                    <v-img
                        v-if="getImageProfile()"
                        style="height: 100%"
                        :src="getImageProfile()"
                    ></v-img>
                    <v-icon v-else :color="getTextIconColor()" size="large">
                        mdi-account
                    </v-icon>
                </v-avatar>
            </v-btn>
        </template>
        <v-list class="font" style="margin-left: -50px">
            <v-list-item
                class="cursor"
                v-if="userStore.isAdmin"
                @click="goToAdminDashboard()"
            >
                <v-list-item-title class="text-body-2 font-weight-bold text-secondary">
                    {{ $t('admin_dashboard') }}
                </v-list-item-title>
            </v-list-item>
            <v-list-item
                class="cursor"
                @click="changeViewType(true)"
                v-if="
                    userStore.allCompanies.length > 0 &&
                    ((!userStore.viewTypeUser && !!approved()) ||
                        (userStore.viewTypeUser && isSmallDevice()))
                "
            >
                <v-list-item-title class="text-body-2 font-weight-bold text-secondary">
                    {{ actionViewType(true) }}
                </v-list-item-title>
            </v-list-item>
            <v-list-item class="cursor" @click="addCompany" v-if="!hasCompany()">
                <v-list-item-title class="text-body-2 font-weight-bold text-secondary">
                    {{ t('add_company') }}
                </v-list-item-title>
            </v-list-item>
            <v-divider
                v-if="
                    (userStore.allCompanies.length > 0 &&
                        ((!userStore.viewTypeUser && !!approved()) ||
                            (userStore.viewTypeUser && isSmallDevice()))) ||
                    !hasCompany()
                "
            ></v-divider>
            <v-list-item @click="goToUserProfile" class="cursor">
                <v-list-item-title class="text-body-2 text-secondary">
                    {{ $t('profile') }}
                </v-list-item-title>
            </v-list-item>
            <v-list-item @click="signoutUser" class="cursor">
                <v-list-item-title class="text-body-2 text-secondary">
                    {{ $t('exit') }}
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script lang="ts" setup>
import { signoutUser } from '@/composables/auth';
import { RoutesNames } from '@/router/routesNames';
import { useRouter } from 'vue-router';
import { defineProps, withDefaults, defineEmits } from 'vue';
import { User, Company, CurrentAppVisualizationType } from '@/graphql/types/graphql';
import { useUserStore } from '@/store/user';
import { useChangeCurrentAppVisualizzation } from '@/composables/user';
import { useI18n } from 'vue-i18n';
import { useDialogsStore } from '@/store/dialogs';
import { Maybe } from '@/graphql/types/graphql';
import { isSmallDevice } from '@/utils/helpers';

const dialogsStore = useDialogsStore();

const { t } = useI18n();
const router = useRouter();
const userStore = useUserStore();

const {
    mutate: changeCurrentAppVisualizzation,
    loading: loadingUpdate,
    onDone: onDonechangeCurrentAppVisualizzation,
} = useChangeCurrentAppVisualizzation();

interface UserMenuProps {
    user: Maybe<User>;
    company: Company;
    isTalent: boolean;
    theme: string;
}

const emit = defineEmits(['onOpenDialogAddCompany']);

const props = withDefaults(defineProps<UserMenuProps>(), {
    user: null,
    isTalent: true,
    theme: 'white',
});

function goToUserProfile() {
    const id = getUserId();
    router.push({
        name: RoutesNames.user_profile,
        params: { id },
    });
}

function goToAdminDashboard() {
    router.push({
        name: RoutesNames.admin_dashboard,
    });
}

function getUserId() {
    return props.user ? props.user.id : null;
}

function getImageProfile() {
    const image = props.user && props.user.photo ? props.user.photo.fileName : null;
    return image;
}

function getButtonColor() {
    const color = props.theme === 'white' ? 'lightGray' : 'white';
    return color;
}

function getTextIconColor() {
    const color = props.theme === 'white' ? 'subtitle' : 'white';
    return color;
}

function hasCompany() {
    return userStore.allCompanies?.length > 0;
}
function approved() {
    return !!companyApproved() && !!userApproved();
}

function companyApproved() {
    return !!userStore.currentCompany?.company_approval_date;
}

function userApproved() {
    return (
        !!userStore.currentCompany?.user_approval_date &&
        userStore.currentCompany.user_approval_date !== ''
    );
}

function addCompany() {
    dialogsStore.addCompany = true;
    emit('onOpenDialogAddCompany');
}

function actionViewType(withAdd) {
    if (!hasCompany() && withAdd) {
        return t('add_company');
    }
    return userStore.viewTypeUser ? t('view_type_company') : t('view_type_user');
}

function getCompanyId() {
    return props.company ? props.company.companyId : null;
}

onDonechangeCurrentAppVisualizzation(() => {
    router.replace({ name: RoutesNames.dashboard });
});

async function changeViewType(withAdd) {
    if (!hasCompany() && withAdd) {
        dialogsStore.addCompany = true;
        emit('onOpenDialogAddCompany');
        return;
    }

    let companyId = getCompanyId();

    if (!companyId) {
        const allCompanies = userStore.allCompanies;
        if (allCompanies && allCompanies.length > 0) {
            companyId = allCompanies[0].companyId;
        }
    }

    const payload: any = {
        type: userStore.viewTypeUser
            ? CurrentAppVisualizationType.Company
            : CurrentAppVisualizationType.User,
    };

    if (payload.type === CurrentAppVisualizationType.Company) {
        payload.companyId = companyId;
    }

    await changeCurrentAppVisualizzation(payload);
}
</script>
<style scoped>
.v-list-item.v-theme--lightTheme.v-list-item--density-default.v-list-item--one-line.v-list-item--variant-text.cursor:hover {
    background-color: #efefef;
}
</style>
