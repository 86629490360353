import { DocumentParameter, OptionsParameter } from './apollo';
import { OperationVariables, TypedDocumentNode } from '@apollo/client/core';
import { UseMutationOptions, useMutation } from '@vue/apollo-composable';

import { DocumentNode } from 'graphql';
import { ReactiveFunction } from '@vue/apollo-composable/dist/util/ReactiveFunction';
import { Ref } from 'vue';

const DEFAULT_FETCH_POLICY = 'no-cache';

export default function mutation<
    TResult = any,
    TVariables extends OperationVariables = OperationVariables
>(
    document: DocumentParameter<TResult, TVariables>,
    variables: TVariables | null = null,
    options: OptionsParameter<TResult, TVariables> | null = null
) {
    return useMutation(document, {
        ...options,
        ...variables,
        fetchPolicy: DEFAULT_FETCH_POLICY,
    });
}

export function uploadMutation<
    TResult = any,
    TVariables extends OperationVariables = OperationVariables
>(
    document: DocumentParameter<TResult, TVariables>,
    variables: TVariables | null = null,
    options: OptionsParameter<TResult, TVariables> | null = null
) {
    return useMutation(document, {
        ...options,
        ...variables,
        fetchPolicy: DEFAULT_FETCH_POLICY,
        context: {
            hasUpload: true,
            headers: {
                'Sec-Fetch-Mode': 'no-cors',
            },
        },
    });
}
