import { PersonalityListDocument } from '@/graphql/types/graphql';
import query from '@/graphql/query';
import { useI18n } from 'vue-i18n';

export enum PersonalityType {
    merit = 'merit',
    defect = 'defect',
}

export function useGetPersonalities(
    personalityType: PersonalityType,
    languageId: string
) {
    const { result, loading, onError } = query(PersonalityListDocument, {
        personalitySection: personalityType,
        language_id: languageId,
    });
    return { result, loading, onError };
}
