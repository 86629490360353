<template>
    <v-app>
        <router-view v-if="!userStore.fetching" name="top"></router-view>
        <router-view v-if="!userStore.fetching" name="left"></router-view>
        <v-main class="pa-0 font">
            <v-container
                style="height: 100vh"
                v-if="userStore.fetching"
                fluid
                class="pa-0 d-flex justify-center align-center"
            >
                <v-progress-circular
                    indeterminate
                    color="primary"
                    size="60"
                ></v-progress-circular>
            </v-container>
            <v-container v-else fluid class="pa-0 ma-0" style="height: 100%">
                <!-- <v-container
                v-else
                fluid
                class="pa-0 ma-0"
                style="height: 100vh; overflow-y: auto; overflow-x: hidden"
            > -->
                <router-view></router-view>
            </v-container>
        </v-main>
        <v-alert
            v-if="errorStore.$state.error"
            title="Errore"
            class="ma-8"
            position="fixed"
            max-width="320px"
            elevation="20"
            location="top right"
            closable
            type="error"
            density="compact"
        >
            {{ errorStore.$state.error }}
        </v-alert>
        <v-alert
            v-if="appmessageStore.$state.message"
            title=""
            class="ma-8"
            position="fixed"
            max-width="320px"
            elevation="20"
            location="top right"
            closable
            type="success"
            density="compact"
        >
            {{ appmessageStore.$state.message }}
        </v-alert>
        <terms-dialog />
        <terms-company-dialog />
        <upload-box v-if="cvStore.uploading" />
        <survey-dialog />
    </v-app>
</template>

<script lang="ts" setup>
import { checkSession } from '@/composables/auth';
import { useUserStore } from '@/store/user';
import { useAppMessageStore } from './store/appmessage';
import { useErrorStore } from './store/error';
import TermsCompanyDialog from '@/components/shared/TermsCompanyDialog.vue';
import TermsDialog from '@/components/shared/TermsDialog.vue';
import SurveyDialog from '@/components/shared/SurveyDialog.vue';
import UploadBox from '@/components/company/UploadBox.vue';
import { useCvUploadStore } from '@/store/cvUploads';
import { loadClarity } from '@/utils/clarity';
import { languageTranslator } from '@/utils/helpers';
import { onBeforeMount, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { RoutesNames } from '@/router/routesNames';
import i18n from '@/i18n';

const errorStore = useErrorStore();
const appmessageStore = useAppMessageStore();

const userStore = useUserStore();
const cvStore = useCvUploadStore();

const router = useRouter();
const route = useRoute();

checkSession();
checkApproved();

function setLocale(selectedLang = null) {
    if (selectedLang) {
        i18n.global.locale = selectedLang;
    } else {
        // const availableLocales = i18n.global.availableLocales;
        // const userLanguage = window.navigator.language.split('-')[0];
        // console.log('availableLocales', availableLocales);
        // if (availableLocales.includes(userLanguage)) {
        //     i18n.global.locale = userLanguage;
        // } else {
        //     i18n.global.locale = 'en';
        // }
        i18n.global.locale = 'en';
    }
}

onBeforeMount(() => {
    setLocale();
    loadClarity();
    const acceptBtn = document.querySelector('.iubenda-cs-accept-btn');
    if (acceptBtn) {
        acceptBtn.addEventListener('click', function () {
            window.clarity('consent');
        });
    }
});

function approved() {
    return !!companyApproved() && !!userApproved();
}

function companyApproved() {
    return !!userStore.currentCompany?.company_approval_date;
}

function userApproved() {
    return (
        !!userStore.currentCompany?.user_approval_date &&
        userStore.currentCompany?.user_approval_date !== ''
    );
}

watch(
    () => userStore.user,
    (data) => {
        if (data) {
            window.clarity('identify', data.id);
            window.clarity('set', 'email', data.email);
            window.clarity('set', 'userName', data.name);
            checkApproved();

            const lang = languageTranslator(data?.languageId);
            setLocale(lang);
        }
    }
);

function checkApproved() {
    if (userStore.user && !userStore.viewTypeUser) {
        if (userStore.loadedCompanies) {
            if (!approved()) {
                if ((route.name as string) !== RoutesNames.company_dashboard) {
                    if (route.path.includes('/company/')) {
                        router.replace({ name: RoutesNames.company_dashboard });
                    }
                }
            }
        }
    }
}

watch(
    () => route.name,
    () => {
        checkApproved();
    }
);

watch(
    () => userStore.loadedCompanies,
    () => {
        if (userStore.loadedCompanies) {
            checkApproved();
        }
    }
);

watch(
    () => userStore.currentCompany,
    (data) => {
        if (data) {
            window.clarity('set', 'companyId', data.companyId);
            window.clarity('set', 'companyName', data.companyName);
            checkApproved();
        }
    }
);
</script>
