export enum EnvVars {
    VUE_APP_BE = 'VUE_APP_BE',
    VUE_APP_FIREBASE_API_KEY = 'VUE_APP_FIREBASE_API_KEY',
    VUE_APP_FIREBASE_DOMAIN = 'VUE_APP_FIREBASE_DOMAIN',
    VUE_APP_FIREBASE_MSG_SENDER_ID = 'VUE_APP_FIREBASE_MSG_SENDER_ID',
    VUE_APP_FIREBASE_APP_ID = 'VUE_APP_FIREBASE_APP_ID',
    VUE_APP_ENVIRONMENT = 'VUE_APP_ENVIRONMENT',
    VUE_APP_CLARITY_ID = 'VUE_APP_CLARITY_ID',
    VUE_APP_DOMAIN = 'VUE_APP_DOMAIN',
    VUE_APP_EDUCATION_DOMAIN = 'VUE_APP_EDUCATION_DOMAIN',
}

function getMetaProp(varName: string): string {
    if (!document) throw Error(`Environment variable, ${varName}, is not defined`);
    return document
        .querySelector(`meta[property=${varName}]`)
        ?.getAttribute('content') as string;
}

export function getEnv(varName: EnvVars): string {
    if (process.env && process.env[`${varName}`] !== undefined) {
        return process.env[`${varName}`] as string;
    } else {
        return getMetaProp(varName);
    }
}

export type Env = Record<EnvVars, string>;
let _env: Env;
export function env(): Env {
    if (!_env)
        _env = (() => {
            const acc: Record<string, any> = {};
            const keys = Object.keys(EnvVars);
            keys.forEach((k) => (acc[k] = getEnv(k as EnvVars)));
            return acc;
        })() as Env;
    return _env;
}

// export function isNotProduction() {
//     return env()['VUE_APP_ENVIRONMENT'] != 'production';
// }

// export function isProduction() {
//     return env()['VUE_APP_ENVIRONMENT'] == 'production';
// }
