// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
// Vuetify
import './main.scss';

import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

import { VDataTable, VDataTableServer, VDataTableVirtual } from 'vuetify/labs/VDataTable';

import { createVuetify } from 'vuetify';

const lightTheme = {
    dark: false,
    colors: {
        surface: '#FFFFFF',
        primary: '#B5DD10',
        secondary: '#142537',
        error: '#B00020',
        info: '#546E7A',
        success: '#4CAF50',
        warning: '#FB8C00',
        title: '#4A4A4A',
        subtitle: '#808080',
        highlight: '#142537',
        actionDisabled: '#515966',
        slider: '#ECF0F4',
        green: '#B6DC44',
        red: '#EF5350',
        violet: '#660966',
        gray: '#ECF0F4',
        darkGray: '#545860',
        skillsBorder: '#C8C8C8',
        skillsText: '#9B9B9B',
        lightGray: '#DDDDDD',
        grayIcon: '#E8E8E8',
        pauseButton: '#E9EFF5',
        pauseIcon: '#9EB2C6',
        cancelButton: '#F7E3EA',
        darkGreen: '#044346',
        darkBlue: '#003E7E',
        darkViolet: '#823F8D',
    },
};

const dataTableTheme = {
    dark: false,
    colors: {
        surface: '#ECF0F4',
        primary: '#B5DD10',
        secondary: '#142537',
        error: '#B00020',
        info: '#546E7A',
        success: '#4CAF50',
        warning: '#FB8C00',
        title: '#4A4A4A',
        subtitle: '#808080',
        highlight: '#142537',
        actionDisabled: '#515966',
        slider: '#ECF0F4',
        green: '#B6DC44',
        red: '#EF5350',
        violet: '#660966',
        gray: '#ECF0F4',
        darkGray: '#545860',
        skillsBorder: '#C8C8C8',
        skillsText: '#9B9B9B',
        lightGray: '#DDDDDD',
        grayIcon: '#E8E8E8',
        pauseButton: '#E9EFF5',
        pauseIcon: '#9EB2C6',
        cancelButton: '#F7E3EA',
        darkGreen: '#044346',
        darkBlue: '#003E7E',
        darkViolet: '#823F8D',
    },
};

export default createVuetify({
    components: {
        ...components,
        VDataTable,
        VDataTableServer,
        VDataTableVirtual,
    },
    directives,
    theme: {
        defaultTheme: 'lightTheme',
        options: { customProperties: true },
        themes: {
            lightTheme,
            dataTableTheme,
        },
    },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
