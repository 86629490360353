import {
    ApolloClient,
    ApolloLink,
    InMemoryCache,
    TypedDocumentNode,
    split,
} from '@apollo/client/core';
import { EnvVars, env } from '@/utils/env';
import { UseMutationOptions, provideApolloClient } from '@vue/apollo-composable';

import { DocumentNode } from 'graphql';
import { ReactiveFunction } from '@vue/apollo-composable/dist/util/ReactiveFunction';
import { Ref } from 'vue';
import { createUploadLink } from 'apollo-upload-client';
import { getCurrentSession } from '@/composables/auth';
import { setContext } from '@apollo/link-context';

import { createClient as SubscriptionClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';

export declare type DocumentParameter<TResult, TVariables> =
    | DocumentNode
    | Ref<DocumentNode>
    | ReactiveFunction<DocumentNode>
    | TypedDocumentNode<TResult, TVariables>
    | Ref<TypedDocumentNode<TResult, TVariables>>
    | ReactiveFunction<TypedDocumentNode<TResult, TVariables>>;
export declare type OptionsParameter<TResult, TVariables> =
    | UseMutationOptions<TResult, TVariables>
    | Ref<UseMutationOptions<TResult, TVariables>>
    | ReactiveFunction<UseMutationOptions<TResult, TVariables>>;

// const httpLink = createHttpLink({
//     uri: env()[EnvVars.VUE_APP_BE] + '/graphql',
// });
const url = env()[`${EnvVars.VUE_APP_BE}`];
const httpLink = createUploadLink({
    uri: url + '/graphql',
});

// Auth Link
const authLink: ApolloLink = setContext(
    async (_operation: any, ctx: { headers: any }) => {
        const { headers } = ctx;

        const token = await getCurrentSession();

        if (token != undefined) {
            try {
                return {
                    headers: {
                        ...headers,
                        'Apollo-Require-Preflight': 'this is non-empty',
                        'x-access-token': `${token}`,
                    },
                };
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                return {
                    headers: {
                        ...headers,
                        'Apollo-Require-Preflight': 'this is non-empty',
                    },
                };
            } catch (error) {
                console.log(error);
            }
        }
        return { headers };
    }
);

// Websocket Link
const wsLink = new GraphQLWsLink(
    SubscriptionClient({
        url: url.replace('https', 'wss') + '/graphql',
    })
);

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    authLink.concat(httpLink)
);

export const apolloClient = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
});

provideApolloClient(apolloClient);
