import { Maybe, Survey, SurveyQuestion } from '@/graphql/types/graphql';

import { defineStore } from 'pinia';

export interface SurveyState {
    id: string;
    releaseId: string;
    title: string;
    questions: SurveyQuestion[];
}

export const useSurveyStore = defineStore('survey', {
    state: (): SurveyState => {
        return {
            id: '',
            releaseId: '',
            title: '',
            questions: [],
        };
    },
});
