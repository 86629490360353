const en = {
    folders: 'Organizational boxes',
    folder: 'organization box',
    my_folders: 'My organizational boxes',
    add_folder: 'Add organization box',
    create_folder_name_hint: 'Organization box name',
    create_folder_description_hint: 'Organization box description',
    confirm_delete_folder:
        'Are you sure you want to delete the <b>{folderName}</b> organization box?',
    search_folder: 'Search organizational box',
    manage_folders: 'Manage organizational boxes',
    parent_folder: 'Parent organization box',
    belonging_folders: 'Belonging organizational boxes',
};
export default en;
