<template>
    <v-dialog class="font" v-model="dialogsStore.whoAreYou" persistent>
        <v-sheet :width="getWidth()" class="pa-6 rounded-xl">
            <v-row no-gutters class="d-flex justify-center">
                <v-col class="px-2">
                    <v-img
                        height="70"
                        width="70"
                        class="mx-auto mb-6"
                        src="@/assets/images/logo-color.svg"
                    ></v-img>
                    <div class="text-h5 text-center mb-4">{{ $t('who_are_you') }}</div>
                    <div class="d-flex hover py-2 justify-space-between align-center">
                        <div>
                            <div class="text-primary font-weight-medium">
                                {{ $t('i_am_talent') }}
                            </div>
                            <div class="text-subtitle-2">
                                {{ $t('and_i_search_job') }}
                            </div>
                        </div>
                        <div>
                            <v-switch
                                v-model="talent"
                                @change="user = !talent"
                                color="primary"
                            ></v-switch>
                        </div>
                    </div>
                    <v-divider />
                    <div class="d-flex py-2 justify-space-between align-center">
                        <div>
                            <div class="bg-hightlight font-weight-medium">
                                {{ $t('i_am_company') }}
                            </div>
                            <div class="text-subtitle-2">
                                {{ $t('and_i_search_talents') }}
                            </div>
                        </div>
                        <div>
                            <v-switch
                                v-model="user"
                                @change="talent = !user"
                                color="secondary"
                            ></v-switch>
                        </div>
                    </div>
                    <v-btn
                        rounded="pill"
                        class="text-uppercase mt-10"
                        color="primary"
                        flat
                        block
                        :loading="loadingUpdate"
                        @click="handleContinue"
                        :disabled="!user && !talent"
                    >
                        {{ $t('continue') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-sheet>
    </v-dialog>
</template>

<script lang="ts" setup>
import { useUpdateUser } from '@/composables/user';
import { useUserStore } from '@/store/user';
import { OnBoardingUser, UserType } from '@/graphql/types/graphql';
import { useDialogsStore } from '@/store/dialogs';
import { ref } from 'vue';
import { useDisplay } from 'vuetify';

const display = useDisplay();

function getWidth() {
    if (display.xs.value) {
        return 300;
    }
    if (display.sm.value) {
        return 500;
    }
    return 600;
}
let dialogsStore = useDialogsStore();
let userStore = useUserStore();

let talent = ref(false);
let user = ref(false);

const {
    mutate: updateUser,
    loading: loadingUpdate,
    onDone: onDoneUpdate,
} = useUpdateUser();

onDoneUpdate((result) => {
    dialogsStore.whoAreYou = false;
});

async function handleContinue() {
    let userType = UserType.Talent;
    if (user.value) {
        userType = UserType.User;
    }
    //Prossimo step dopo la scelta del tipo utente è Name per mantenere congruità con l'applicazione
    await updateUser({
        id: userStore.getUserId,
        onboarding_user_step: OnBoardingUser.Name,
        userType: userType,
    });
}
</script>
