import { Qualification } from '@/graphql/types/graphql';
import { defineStore } from 'pinia';

export interface QualificationsState {
    qualifications: Qualification[];
    filtered: string[];
    filteredObjects: Qualification[];
    loading: boolean;
}

export const useQualificationsStore = defineStore('qualifications', {
    state: (): QualificationsState => {
        return {
            qualifications: [],
            filtered: [],
            filteredObjects: [],
            loading: false,
        };
    },
    actions: {
        filter(searchText: string) {
            this.filtered = this.qualifications
                .map((qualification) => {
                    return qualification.des;
                })
                .filter((des) => {
                    return (
                        (des || '')
                            .toLowerCase()
                            .indexOf((searchText || '').toLowerCase()) > -1
                    );
                });
        },
        filterObjects(searchText: string) {
            this.filteredObjects = this.qualifications.filter((qual) => {
                return (
                    (qual.des || '')
                        .toLowerCase()
                        .indexOf((searchText || '').toLowerCase()) > -1
                );
            });
        },
        resetFiltered() {
            this.filtered = [];
            this.filteredObjects = [];
        },
        getAll() {
            this.filtered = this.qualifications.map((qualification) => {
                return qualification.des;
            });
        },
    },
});
