import _ from 'lodash';
import { defineStore } from 'pinia';

const ERROR_MESSAGE_DELAY = 5000;

export interface ErrorState {
    error: string;
}

export const useErrorStore = defineStore('error', {
    state: (): ErrorState => {
        return {
            error: '',
        };
    },
    actions: {
        setError(error: string) {
            this.error = error;
            setTimeout(() => {
                this.error = '';
            }, ERROR_MESSAGE_DELAY);
        },
    },
});
