import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getEnv, EnvVars } from '@/utils/env';

const firebaseConfig = {
    apiKey: getEnv(EnvVars.VUE_APP_FIREBASE_API_KEY),
    authDomain: getEnv(EnvVars.VUE_APP_FIREBASE_DOMAIN) + '.firebaseapp.com',
    projectId: getEnv(EnvVars.VUE_APP_FIREBASE_DOMAIN),
    storageBucket: getEnv(EnvVars.VUE_APP_FIREBASE_DOMAIN) + '.appspot.com',
    messagingSenderId: getEnv(EnvVars.VUE_APP_FIREBASE_MSG_SENDER_ID),
    appId: getEnv(EnvVars.VUE_APP_FIREBASE_APP_ID),
};

export function initFirebase() {
    getAuth(initializeApp(firebaseConfig));
}
