<template>
    <div>
        <v-checkbox
            class="ml-n2"
            v-model="companyExists"
            hide-details
            color="primary"
            :label="$t('company_exists')"
        />
        <div v-if="!companyExists">
            <v-select
                v-model="countryCompany"
                :items="countries"
                class="mt-4 mb-4"
                :label="$t('country_company')"
                item-title="label"
                item-value="value"
                variant="underlined"
                hide-details
                color="primary"
            ></v-select>
            <v-text-field
                v-if="countryCompany == CompanyCountry.Eu && !companyExists"
                v-model="vat"
                class="mt-4"
                :type="'text'"
                color="primary"
                :label="`${$t('vat')} *`"
                variant="underlined"
                placeholder="IT12345678901"
                @change="emit('onVatChange', vat)"
                :rules="[(v) => !!isEuVatNumber(v) || $t('invalid_vat')]"
            ></v-text-field>
            <div v-if="vatExist">
                <div class="text-body-2 text-warning mt-4 mb-6">
                    {{ $t('company_vat_already_exist') }}
                </div>
            </div>
            <v-text-field
                v-if="!companyExists"
                v-model="companyName"
                :label="`${$t('name')} *`"
                class="mt-4"
                variant="underlined"
                hide-details
                :type="'text'"
                @change="emit('onNameChange', companyName)"
                color="primary"
            ></v-text-field>
        </div>
        <div v-if="companyExists">
            <v-text-field
                v-if="companyExists"
                v-model="companyId"
                class="mt-4"
                :type="'text'"
                color="primary"
                label="Company ID"
                variant="underlined"
                placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
            ></v-text-field>
            <v-btn
                v-if="companyExists"
                rounded="pill"
                class="text-uppercase mt-2 mb-2"
                color="primary"
                flat
                :disabled="!companyId"
                @click="searchCompany"
            >
                {{ $t('search') }}
            </v-btn>
        </div>

        <div
            v-if="companyStore.loading"
            style="align-items: center; display: flex; justify-content: center"
            class="mt-2"
        >
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <div v-if="selectedCompany && !userAlreadyInCompany && !companyStore.loading">
            <v-card class="pa-2 mt-4" elevation="0" variant="outlined" color="gray">
                <div class="text-body-2 text-subtitle">
                    {{ $t('company_found_request_access') }}
                </div>
                <div class="text-primary text-body-2 mt-2">
                    {{ selectedCompany.name }}
                    <v-icon color="primary">mdi-check</v-icon>
                </div>
            </v-card>
        </div>
        <div v-if="userAlreadyInCompany && !companyStore.loading">
            <div class="text-body-2 text-warning mt-4 mb-6">
                {{ $t('user_already_in_company') }}
            </div>
        </div>
        <div v-if="companyNotFound && !companyStore.loading">
            <div class="text-body-2 text-warning mt-4 mb-6">
                {{ $t('company_not_found') }}
            </div>
        </div>
        <v-select
            v-if="selectedCompany && !userAlreadyInCompany && !companyStore.loading"
            v-model="selectedRole"
            :items="roleList"
            class="mt-6 mb-6"
            :label="$t('role')"
            variant="underlined"
            hide-details
            color="primary"
        ></v-select>
    </div>
</template>

<script lang="ts" setup>
import { useSearchCompanyPublicById } from '@/composables/companies';
import { useCompanyStore } from '@/store/companies';
import { useUserStore } from '@/store/user';
import { ref, watch, defineEmits, withDefaults, defineProps } from 'vue';
import _ from 'lodash';
import {
    CompanyEmployeeRole,
    CompanyCountry,
    CompanyPublic,
} from '@/graphql/types/graphql';
import { useI18n } from 'vue-i18n';
import { isEuVatNumber } from '@/utils/helpers';

interface CompanySelectorProps {
    isOnboarding: boolean;
    showErrorVatExist: boolean;
    setUserAlreadyInCompany: (val: boolean) => void;
    setCompanyRegistered: (val: boolean) => void;
}

const props = withDefaults(defineProps<CompanySelectorProps>(), {
    isOnboarding: false,
    showErrorVatExist: false,
});

const { t } = useI18n();

let companyRoles = Object.values(CompanyEmployeeRole).filter(
    (el) => el !== CompanyEmployeeRole.Admin
);

const roles = companyRoles.map((el) => ({
    label: t((el as string).toLowerCase()),
    value: el,
}));

const roleList = roles.map((el) => el.label);

let companyCountries = Object.values(CompanyCountry);

let vatExist = ref(false);

const countries = companyCountries.map((el) => ({
    label: t((el as string).toLowerCase()),
    value: el,
}));

const emit = defineEmits([
    'select',
    'selectRole',
    'onVatChange',
    'onNameChange',
    'selectCountry',
]);

const companyStore = useCompanyStore();
const userStore = useUserStore();
const vat = ref('');
const selectedCompany = ref<CompanyPublic | null>(null);
const selectedRole = ref(null);
const countryCompany = ref(null);
const companyExists = ref(false);
const companyId = ref('');
const companyName = ref('');
const companyNotFound = ref(false);

const userCompaniesId = userStore.allCompanies
    ? userStore.allCompanies.map((el) => el.companyId)
    : [];

const userAlreadyInCompany = ref(false);

watch(selectedRole, (value) => {
    if (value) {
        const role = roles.find((el) => (el as any).label === value);
        emit('selectRole', role.value);
    }
});

watch(
    () => props.showErrorVatExist,
    (val) => {
        vatExist.value = val;
    }
);

watch(vat, () => {
    vatExist.value = false;
});

watch(countryCompany, (country) => {
    if (country) {
        emit('selectCountry', country);
        if (countryCompany.value !== CompanyCountry.Eu) {
            vat.value = '';
        }
    }
});

watch(companyExists, (value) => {
    props.setCompanyRegistered(value);
    companyNotFound.value = false;

    if (!value) {
        selectedRole.value = null;
        vat.value = '';
        countryCompany.value = null;
        selectedCompany.value = null;
        emit('select', selectedCompany.value);
        companyId.value = '';
        companyName.value = '';
    } else {
        companyName.value = '';
        vat.value = '';
    }
});

function searchCompany() {
    const { result, onError } = useSearchCompanyPublicById(companyId.value);
    companyStore.loading = true;
    companyNotFound.value = false;
    userAlreadyInCompany.value = false;
    props.setUserAlreadyInCompany(false);
    watch(result, (data) => {
        if (data && data.CompanyPublicById) {
            if (userCompaniesId.includes(data.CompanyPublicById.id)) {
                userAlreadyInCompany.value = true;
                props.setUserAlreadyInCompany(true);
            } else {
                selectedCompany.value = data.CompanyPublicById;
                emit('select', selectedCompany.value);
            }
            companyStore.loading = false;
        }
    });

    onError((result) => {
        console.error(result.message);
        companyStore.loading = false;
        companyNotFound.value = true;
    });
}
</script>
<style scoped>
.pointer {
    cursor: pointer !important;
}
</style>
