import { defineStore } from 'pinia';
import {
    JobAnnouncement,
    Maybe,
    SearchJobAnnouncementStatus,
} from '@/graphql/types/graphql';

export interface JobAnnouncementState {
    list: JobAnnouncement[];
    listCompany: JobAnnouncement[];
    pageLimitCompany: number;
    totalJobCompany: number;
    totalPageJobCompany: number;
    newJob: Maybe<JobAnnouncement>;
    jobCompanyStateFilter: SearchJobAnnouncementStatus;
}

export const useJobAnnouncementStore = defineStore('jobAnnouncement', {
    state: (): JobAnnouncementState => {
        return {
            list: [],
            listCompany: [],
            pageLimitCompany: 9,
            totalJobCompany: 0,
            totalPageJobCompany: 0,
            newJob: {},
            jobCompanyStateFilter: SearchJobAnnouncementStatus.All,
        };
    },
    actions: {
        setList(data: JobAnnouncement[]) {
            this.list = data;
        },
        setListCompany(data: JobAnnouncement[]) {
            this.listCompany = data;
        },
        resetNewJob() {
            this.newJob = {};
        },
    },
});
