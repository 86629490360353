import { Company, CompanyPublic, SavedSearch } from '@/graphql/types/graphql';

import { Maybe } from 'graphql/jsutils/Maybe';
import { defineStore } from 'pinia';

type CompanyContact = {
    phone: Maybe<string>;
    email: Maybe<string>;
};

export interface CompanyState {
    userCompany: Partial<Maybe<Company & CompanyContact>>;
    companies: Maybe<Company[]>;
    publicCompanies: Maybe<CompanyPublic[]>;
    loading: boolean;
    savedSearches: Maybe<SavedSearch[]>;
    totalSavedSearches: number;
    currentPageSavedSearches: number;
    loadingsavedSearches: boolean;
    totalPageSavedSearches: number;
}

export const useCompanyStore = defineStore('companies', {
    state: (): CompanyState => {
        return {
            userCompany: null,
            companies: null,
            publicCompanies: null,
            loading: false,
            savedSearches: [],
            totalSavedSearches: 0,
            totalPageSavedSearches: 0,
            currentPageSavedSearches: 1,
            loadingsavedSearches: false,
        };
    },
});
