const it = {
    folders: 'Box organizzativi',
    folder: 'box organizzativo',
    my_folders: 'I miei box organizzativi',
    add_folder: 'Aggiungi box organizzativo',
    create_folder_name_hint: 'Nome box organizzativo',
    create_folder_description_hint: 'Descrizione box organizzativo',
    confirm_delete_folder:
        'Confermi di voler eliminare il box organizzativo <b>{folderName}</b>?',
    search_folder: 'Cerca box organizzativo',
    manage_folders: 'Gestisci box organizzativi',
    parent_folder: 'Box organizzativo genitore',
    belonging_folders: 'Box organizzativi di appartenenza',
};
export default it;
