import { Skill } from '@/graphql/types/graphql';
import _ from 'lodash';
import { defineStore } from 'pinia';

export interface SkillsState {
    skills: Skill[];
    skillsForQualification: Skill[];
    filtered: Skill[];
    loading: boolean;
    selected: Skill[];
    filteredSingleSelector: string[];
}

export const useSkillsStore = defineStore('skills', {
    state: (): SkillsState => {
        return {
            skills: [],
            skillsForQualification: [],
            filtered: [],
            loading: false,
            selected: [],
            filteredSingleSelector: [],
        };
    },
    actions: {
        filter(searchText: string) {
            this.filtered = this.skills.filter((s) => {
                return (
                    (s.des || '')
                        .toLowerCase()
                        .indexOf((searchText || '').toLowerCase()) > -1
                );
            });
        },
        resetFiltered() {
            //this.filtered = this.skills.map((el) => el!.des) as string[];
            this.filtered = [];
        },
        filterForSingleSelector(searchText: string) {
            this.filteredSingleSelector = this.skills
                .map((skill) => {
                    return skill.des;
                })
                .filter((des) => {
                    return (
                        (des || '')
                            .toLowerCase()
                            .indexOf((searchText || '').toLowerCase()) > -1
                    );
                });
        },
        getAll() {
            this.filteredSingleSelector = this.skills.map((skill) => {
                return skill.des;
            });
        },
    },
});
