<template>
    <div :style="getStyle()">
        <div class="d-flex">
            <div
                class="pl-6 d-row align-center justify-start v-col-sm-5 v-col-md-3 v-col-lg-3 v-col-xl-2 v-col-3"
            >
                <v-img
                    v-if="props.theme === 'white'"
                    height="50"
                    width="50"
                    class="cursor"
                    @click="checkViewType"
                    src="@/assets/images/logo-color.svg"
                ></v-img>
                <v-img
                    v-else
                    height="50"
                    width="50"
                    class="cursor"
                    @click="checkViewType"
                    src="@/assets/images/logo-white.svg"
                ></v-img>
            </div>
            <div
                v-if="
                    (route.name == RoutesNames.company_dashboard ||
                        route.name == RoutesNames.management_dashboard) &&
                    companyApproved() &&
                    userApproved() &&
                    !isSmallDevice()
                "
                class="d-flex d-row align-center justify-start"
            >
                <v-btn
                    v-for="dashboard in dashboards"
                    :key="dashboard.label"
                    elevation="0"
                    variant="outlined"
                    :color="getDashboardButtonColor(dashboard.route as RoutesNames)"
                    rounded="pill"
                    :class="dashboard.class"
                    @click="openDashboard(dashboard.label)"
                >
                    <div
                        :class="
                            getDashboardButtonTextStyle(dashboard.route as RoutesNames)
                        "
                    >
                        {{ dashboard.label }}
                    </div>
                </v-btn>
            </div>
            <div
                v-if="props.type === 'public' && !!companyName"
                class="d-flex align-center justify-center flex-column"
                style="flex: 1"
            >
                <div class="font-weight-bold text-h6 text-secondary">
                    {{ capitalize((companyName as string).replaceAll('-', ' ')) }}
                </div>
                <v-divider color="secondary" thickness="2" width="18" />
            </div>
            <div class="d-flex align-center justify-end" style="flex: 1">
                <v-btn
                    v-if="
                        showActionMenu() &&
                        (!!userStore.viewTypeUser || !showOtherElementsMenu()) &&
                        props.type !== 'public' &&
                        !isSmallDevice() &&
                        !!approved()
                    "
                    :color="props.theme === 'white' ? 'subtitle' : 'white'"
                    density="comfortable"
                    elevation="0"
                    variant="text"
                    class="font mr-4 text-none"
                    @click="changeViewType"
                >
                    {{ actionViewType() }}
                </v-btn>
                <v-btn
                    v-if="
                        showActionMenu() &&
                        !showOtherElementsMenu() &&
                        hasMoreCompany() &&
                        props.type !== 'public'
                    "
                    :color="props.theme === 'white' ? 'subtitle' : 'white'"
                    density="comfortable"
                    elevation="0"
                    variant="text"
                    class="font mr-4 text-none"
                    @click="dialogsStore.changeCompany = true"
                >
                    {{ $t('change_company') }}
                </v-btn>
                <language-menu
                    v-if="showOtherElementsMenu() || props.type === 'public'"
                    :showTitle="!isSmallDevice()"
                    :theme="props.theme"
                />
                <div
                    class="mr-4"
                    v-if="
                        showActionMenu() && showCompanyMenu() && props.type !== 'public'
                    "
                />
                <!-- <v-badge
                    v-if="showActionMenu() && userStore.viewTypeUser"
                    content="2"
                    color="primary"
                    class="ml-4 mr-4"
                >
                    <v-btn
                        :color="props.theme === 'white' ? 'lightGray' : 'white'"
                        elevation="0"
                        rounded="pill"
                        variant="outlined"
                        class="font"
                        size="x-small"
                        icon
                    >
                        <v-icon
                            :color="props.theme === 'white' ? 'subtitle' : 'white'"
                            size="default"
                        >
                            mdi-bell-outline
                        </v-icon>
                    </v-btn>
                </v-badge> -->
                <company-profile-menu
                    v-if="
                        showActionMenu() && showCompanyMenu() && props.type !== 'public'
                    "
                    :company="userStore.currentCompany"
                    :theme="props.theme"
                    @onOpenDialogAddCompany="onOpenDialogAddCompany"
                />
                <div class="mr-4" />
                <user-profile-menu
                    v-if="showActionMenu() && !!userStore.user && props.type !== 'public'"
                    :user="userStore.user"
                    :isTalent="
                        userStore.viewTypeUser === null ? false : userStore.viewTypeUser
                    "
                    :theme="props.theme"
                    :company="userStore.currentCompany"
                    @onOpenDialogAddCompany="onOpenDialogAddCompany"
                />
                <v-btn
                    v-if="!showActionMenu() && userStore.user && props.type !== 'public'"
                    class="font ml-6"
                    flat
                    variant="text"
                    rounded="pill"
                    color="title"
                    @click="signoutUser"
                >
                    {{ $t('exit') }}
                </v-btn>
                <div class="mr-4" />
            </div>
        </div>
        <add-company-dialog
            @companyAdded="companyAdded"
            @companyAddSelected="companyAddSelected"
            :showErrorVatExist="showErrorVatExist"
            :closed="closedDialog"
        />
        <change-company-dialog @companySelected="companySelected" />
        <v-divider
            v-if="!showOtherElementsMenu() && props.type !== 'public'"
            color="primary"
            thickness="4"
        />
        <v-divider v-else />
    </div>
</template>

<script lang="ts" setup>
import { useUserStore } from '@/store/user';
import { CurrentAppVisualizationType } from '@/graphql/types/graphql';
import LanguageMenu from '@/components/structure/LanguageMenu';
import UserProfileMenu from '@/components/structure/UserProfileMenu';
import CompanyProfileMenu from '@/components/structure/CompanyProfileMenu';
import AddCompanyDialog from '@/components/company/AddCompanyDialog';
import ChangeCompanyDialog from '@/components/company/ChangeCompanyDialog';
import { useI18n } from 'vue-i18n';
import { withDefaults, defineProps, ref } from 'vue';
import { useChangeCurrentAppVisualizzation } from '@/composables/user';
import { useAddCompany, useAddMeToCompany } from '@/composables/companies';
import { useDialogsStore } from '@/store/dialogs';
import { useRouter, useRoute } from 'vue-router';
import { RoutesNames } from '@/router/routesNames';
import { checkViewType } from '@/composables/auth';
import { signoutUser, goToDashboard } from '@/composables/auth';
import { capitalize, isSmallDevice } from '@/utils/helpers';
import { EnvVars, getEnv } from '@/utils/env';

const router = useRouter();
const route = useRoute();
const dialogsStore = useDialogsStore();
const { t } = useI18n();

const dashboards = [
    {
        label: 'Hiring & Staffing',
        route: RoutesNames.company_dashboard,
        class: '',
    },
    {
        label: 'Management',
        route: RoutesNames.management_dashboard,
        class: 'ml-4',
    },
    {
        label: 'Training',
        route: '',
        class: 'ml-4',
    },
];

interface AppNavbarProps {
    theme?: string;
    type?: string;
}

const props = withDefaults(defineProps<AppNavbarProps>(), {
    theme: 'white', // DEFAULT,
    type: 'internal',
});

const userStore = useUserStore();

const companyName = route.params.company;

const showErrorVatExist = ref(false);
const closedDialog = ref(false);

const {
    mutate: changeCurrentAppVisualizzation,
    loading: loadingUpdate,
    onDone: onDonechangeCurrentAppVisualizzation,
} = useChangeCurrentAppVisualizzation();

const {
    mutate: addCompany,
    loading: loadingAdd,
    onDone: onDoneAdd,
    result: resultAddCompany,
    onError: onErrorAddCompany,
} = useAddCompany(false);

const {
    mutate: addMeToCompany,
    loading: loadingAddMe,
    onDone: onDoneAddMe,
} = useAddMeToCompany();

onDoneAdd((result) => {
    closedDialog.value = true;
    dialogsStore.addCompany = false;
});
onDoneAddMe(() => {
    closedDialog.value = true;
    dialogsStore.addCompany = false;
});
onErrorAddCompany((error) => {
    if (error.message.includes('Vat number already taken')) {
        showErrorVatExist.value = true;
        closedDialog.value = false;
    } else {
        closedDialog.value = true;
        dialogsStore.addCompany = false;
    }
});

function approved() {
    return !!companyApproved() && !!userApproved();
}

function actionViewType() {
    if (!hasCompany()) {
        return t('add_company');
    }
    return userStore.viewTypeUser ? t('view_type_company') : t('view_type_user');
}

function companyApproved() {
    return !!userStore.currentCompany?.company_approval_date;
}

function userApproved() {
    return (
        !!userStore.currentCompany?.user_approval_date &&
        userStore.currentCompany.user_approval_date !== ''
    );
}

function showActionMenu() {
    if (!userStore.user) {
        return false;
    }
    if (
        !userStore.privacyAccepted ||
        !userStore.emailVerified ||
        !userStore.secondTermsAccepted
    ) {
        return false;
    }
    return true;
}

function showCompanyMenu() {
    return !userStore.viewTypeUser && !!companyApproved() && !!userApproved();
}

function showOtherElementsMenu() {
    return showCompanyMenu() || !!userStore.viewTypeUser;
}

function onOpenDialogAddCompany() {
    closedDialog.value = false;
    showErrorVatExist.value = false;
}

async function changeViewType() {
    if (!hasCompany()) {
        dialogsStore.addCompany = true;
        onOpenDialogAddCompany();
        return;
    }

    let companyId = userStore.currentCompany ? userStore.currentCompany.companyId : null;

    if (!companyId) {
        const allCompanies = userStore.allCompanies;
        companyId = allCompanies[0].companyId;
    }

    const payload: any = {
        type: userStore.viewTypeUser
            ? CurrentAppVisualizationType.Company
            : CurrentAppVisualizationType.User,
    };

    if (payload.type === CurrentAppVisualizationType.Company) {
        payload.companyId = companyId;
    }

    await changeCurrentAppVisualizzation(payload);
}

function hasCompany() {
    return userStore.allCompanies?.length > 0;
}
function hasMoreCompany() {
    return userStore.allCompanies?.length > 1;
}

async function companyAdded(company) {
    await addCompany({
        input: company,
    });
}

async function companyAddSelected({ company, role }) {
    await addMeToCompany({ role: role.toUpperCase(), companyId: company.id });
}

async function companySelected(companyId) {
    await changeCurrentAppVisualizzation({
        companyId,
    });
}
onDonechangeCurrentAppVisualizzation(() => {
    goToDashboard();
});

function goTo(routeName: RoutesNames) {
    router.push({ name: routeName });
}

function getStyle() {
    if (props.theme === 'white') {
        return 'z-index: 1; background-color: #fff';
    }
    return '';
}

function getDashboardButtonTextStyle(routeName: RoutesNames) {
    var style = 'font text-body-2 ';
    if (route.name == routeName) {
        return style + 'font-weight-bold text-primary';
    } else return style + 'text-secondary';
}

function getDashboardButtonColor(routeName: RoutesNames) {
    if (route.name == routeName) {
        return 'primary';
    } else return 'lightGray';
}

function openDashboard(name: string) {
    if (name == 'Hiring & Staffing') {
        router.push({ name: RoutesNames.company_dashboard });
    } else if (name == 'Management') {
        router.push({ name: RoutesNames.management_dashboard });
    } else if (name == 'Training') {
        window.location.href =
            getEnv(EnvVars.VUE_APP_EDUCATION_DOMAIN) + '/company/dashboard';
    }
}
</script>
