<template>
    <div>
        <v-navigation-drawer
            app
            class="d-none d-sm-flex bg-transparent ml-6"
            rail
            rail-width="64"
            rounded
            touchless
            elevation="0"
            style="margin-top: 121px"
        >
            <v-list height="20vh" class="bg-transparent"></v-list>
            <div>
                <v-card class="ml-3 mr-1 pa-0 rounded-xl">
                    <v-list density="compact" class="ml-0 pa-0 rounded-xl" nav>
                        <v-tooltip
                            :text="$t('dashboard_menu')"
                            transition="slide-x-transition"
                            origin="auto"
                        >
                            <template v-slot:activator="{ props }">
                                <v-list-item
                                    v-bind="props"
                                    color="#8E959C"
                                    active-color="#142537"
                                    link
                                    class="pl-3 pt-3 pb-3 rounded-xl"
                                    prepend-icon="mdi-home-outline"
                                    @click="router.push({ name: RoutesNames.dashboard })"
                                ></v-list-item>
                            </template>
                        </v-tooltip>
                        <v-tooltip transition="slide-x-transition" origin="auto">
                            <template v-slot:activator="{ props }">
                                <v-list-item
                                    v-bind="props"
                                    color="#8E959C"
                                    active-color="#142537"
                                    link
                                    class="pl-3 pt-3 pb-3 rounded-xl"
                                    prepend-icon="mdi-magnify"
                                ></v-list-item>
                            </template>
                            <v-badge content="Coming soon" color="primary" class="mb-1">
                                <span class="pt-3 pb-2">{{ $t('search_menu') }}</span>
                            </v-badge>
                        </v-tooltip>
                        <v-tooltip
                            transition="slide-x-transition"
                            origin="auto"
                            :text="$t('jobs_menu')"
                        >
                            <template v-slot:activator="{ props }">
                                <v-list-item
                                    v-bind="props"
                                    color="#8E959C"
                                    active-color="#142537"
                                    link
                                    class="pl-3 pt-3 pb-3 rounded-xl"
                                    prepend-icon="mdi-thumb-up-outline"
                                    @click="
                                        router.push({
                                            name: RoutesNames.jobs,
                                            params: { type: 'applications' },
                                        })
                                    "
                                ></v-list-item>
                            </template>
                        </v-tooltip>

                        <v-tooltip
                            :text="$t('match_menu')"
                            transition="slide-x-transition"
                            origin="auto"
                        >
                            <template v-slot:activator="{ props }">
                                <v-list-item
                                    v-bind="props"
                                    color="#8E959C"
                                    active-color="#142537"
                                    link
                                    class="pl-3 pt-3 pb-3 rounded-xl"
                                    prepend-icon="mdi-sync"
                                ></v-list-item>
                            </template>
                            <v-badge content="Coming soon" color="primary" class="mb-1">
                                <span class="pt-3 pb-2">{{ $t('match_menu') }}</span>
                            </v-badge>
                        </v-tooltip>
                        <v-tooltip
                            :text="$t('learning_menu')"
                            transition="slide-x-transition"
                            origin="auto"
                        >
                            <template v-slot:activator="{ props }">
                                <v-list-item
                                    v-bind="props"
                                    color="#8E959C"
                                    active-color="#142537"
                                    link
                                    class="pl-3 pt-3 pb-3 rounded-xl"
                                    prepend-icon="mdi-lightbulb-outline"
                                    @click="openEducation()"
                                ></v-list-item>
                            </template>
                        </v-tooltip>
                    </v-list>
                </v-card>
            </div>
        </v-navigation-drawer>
        <v-bottom-navigation
            app
            fixed
            hide-on-scroll
            horizontal
            v-model="mobileNav"
            class="d-flex d-sm-none"
        >
            <v-btn class="btn-bottom" :value="RoutesNames.dashboard">
                <v-icon>mdi-home-outline</v-icon>
            </v-btn>

            <v-btn class="btn-bottom" :value="RoutesNames.search">
                <v-icon>mdi-magnify</v-icon>
            </v-btn>

            <v-btn class="btn-bottom" :value="RoutesNames.jobs">
                <v-icon>mdi-thumb-up-outline</v-icon>
            </v-btn>
            <v-btn class="btn-bottom" :value="RoutesNames.matches">
                <v-icon>mdi-sync</v-icon>
            </v-btn>
            <v-btn class="btn-bottom" :value="RoutesNames.learning">
                <v-icon>mdi-lightbulb-outline</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </div>
</template>
<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { RoutesNames } from '@/router/routesNames';
import { EnvVars, getEnv } from '@/utils/env';

const router = useRouter();

const mobileNav = ref(RoutesNames.dashboard);

watch(
    () => mobileNav,
    () => {
        router.push({ name: mobileNav.value });
    },
    { deep: true }
);

function openEducation() {
    window.location.href = getEnv(EnvVars.VUE_APP_EDUCATION_DOMAIN) + '/dashboard';
}
</script>
<style>
.drawer {
    top: 0;
    border-inline-end-width: none !important;
}
.bg-transparent {
    background: transparent !important;
}
.btn-bottom {
    min-width: 60px !important;
}
.v-navigation-drawer--left {
    border: none !important;
}
</style>
