import { defineStore } from 'pinia';

export interface TeamListState {
    newTeamList: any;
    list: any;
    loadingList: boolean;
    requestedList: boolean;
}

export const useTeamListStore = defineStore('teamlist', {
    state: (): TeamListState => {
        return {
            list: [],
            loadingList: false,
            requestedList: false,
            newTeamList: {
                id: null,
                currentStep: 1,
                title: null,
                subtitle: null,
                state: null,
                items: [
                    {
                        id: 1,
                        profile: null,
                        workLoad: null,
                        skills: [],
                        experience: null,
                        daily_fare: null,
                        activity: null,
                        responsability: null,
                    },
                    {
                        id: 2,
                        profile: null,
                        workLoad: null,
                        skills: [],
                        experience: null,
                        daily_fare: null,
                        activity: null,
                        responsability: null,
                    },
                    {
                        id: 3,
                        profile: null,
                        workLoad: null,
                        skills: [],
                        experience: null,
                        daily_fare: null,
                        activity: null,
                        responsability: null,
                    },
                ],
                savedSearches: [],
            },
        };
    },
    actions: {
        isOpenNewTeamInTable(id: string) {
            const element = this.newTeamList.items.find((el: any) => el.id === id);

            return (
                element.skills.length > 0 ||
                !!element.experience ||
                !!element.daily_fare ||
                !!element.activity ||
                !!element.responsability
            );
        },
        setSkillsOnItem(skills: any, index: number) {
            this.newTeamList.items[index] = {
                ...this.newTeamList.items[index],
                skills,
            };
        },
        removeSkillOnItem(skill: any, index: number) {
            this.newTeamList.items[index] = {
                ...this.newTeamList.items[index],
                skills: this.newTeamList.items[index].skills.filter(
                    (s: any) => s.id !== skill.id
                ),
            };
        },
        setQualificationOnItem(qualification: any, index: number) {
            this.newTeamList.items[index] = {
                ...this.newTeamList.items[index],
                profile: qualification,
            };
        },
        setExperienceOnItem(range: any, index: number) {
            this.newTeamList.items[index] = {
                ...this.newTeamList.items[index],
                experience: {
                    min: range[0],
                    max: range[1],
                },
            };
        },
        setDailyFareOnItem(range: any, index: number) {
            this.newTeamList.items[index] = {
                ...this.newTeamList.items[index],
                daily_fare: {
                    min: range[0],
                    max: range[1],
                },
            };
        },
        resetNewTeamList() {
            this.newTeamList = {
                currentStep: 1,
                title: null,
                subtitle: null,
                state: null,
                items: [
                    {
                        id: 1,
                        profile: null,
                        workLoad: null,
                        skills: [],
                        experience: null,
                        daily_fare: null,
                        activity: null,
                        responsability: null,
                    },
                    {
                        id: 2,
                        profile: null,
                        workLoad: null,
                        skills: [],
                        experience: null,
                        daily_fare: null,
                        activity: null,
                        responsability: null,
                    },
                    {
                        id: 3,
                        profile: null,
                        workLoad: null,
                        skills: [],
                        experience: null,
                        daily_fare: null,
                        activity: null,
                        responsability: null,
                    },
                ],
            };
        },
        addNewItem() {
            this.newTeamList.items.push({
                id: this.newTeamList.items.length + 1,
                profile: null,
                workLoad: null,
                skills: [],
                experience: null,
                daily_fare: null,
                activity: null,
                responsability: null,
            });
        },
        removeItem(index: number) {
            this.newTeamList.items = this.newTeamList.items.filter(
                (el: any, i: number) => i !== index
            );
        },
        saveResultForSavedSearch(savedSearch: any, results: any) {
            const savedSearchIndex = this.newTeamList.savedSearches.findIndex(
                (s: any) => s.id === savedSearch.id
            );
            if (savedSearchIndex > -1) {
                this.newTeamList.savedSearches[savedSearchIndex].requestedData = true;
                const previousResult =
                    this.newTeamList.savedSearches[savedSearchIndex].results;
                const previousResultCandidatesIds = previousResult.map(
                    (el: any) => el.talent.id
                );
                this.newTeamList.savedSearches[savedSearchIndex].other_candidates =
                    results.filter(
                        (r: any) => !previousResultCandidatesIds.includes(r.id)
                    );
            }
        },
    },
});
