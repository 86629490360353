<template>
    <v-dialog
        class="font"
        v-model="dialogsStore.changeCompany"
        @click:outside="closeDialog"
    >
        <v-sheet :width="getWidth()" class="pb-6 pt-1 px-1 rounded-xl">
            <v-row no-gutters justify="end" align-items="center">
                <v-btn
                    @click="closeDialog"
                    style="float: right; right: 0px"
                    fab
                    absolute
                    icon
                    color="white"
                    variant="text"
                    class=""
                >
                    <v-icon color="secondary">mdi-close</v-icon>
                </v-btn>
            </v-row>
            <v-row no-gutters justify="center" align-items="center" class="px-6">
                <v-col class="px-2">
                    <div>
                        <div class="text-h5 text-center">{{ $t('choose_company') }}</div>
                        <user-companies-selector
                            @select="chooseCompany"
                            :value="selectedCompany"
                            class="mt-6"
                        ></user-companies-selector>
                        <v-btn
                            rounded="pill"
                            class="text-uppercase mt-6"
                            color="primary"
                            flat
                            block
                            @click="saveChooseCompany()"
                        >
                            {{ $t('save') }}
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-sheet>
    </v-dialog>
</template>

<script lang="ts" setup>
import { useDialogsStore } from '@/store/dialogs';
import { useUserStore } from '@/store/user';
import { ref, defineEmits, watch } from 'vue';
import UserCompaniesSelector from '@/components/shared/UserCompaniesSelector';
import { useDisplay } from 'vuetify';

const display = useDisplay();

function getWidth() {
    if (display.xs.value) {
        return 300;
    }

    return 400;
}

const emit = defineEmits(['companySelected']);

const dialogsStore = useDialogsStore();
const userStore = useUserStore();

const selectedCompany = ref(userStore.currentCompany);
const companyName = ref('');

watch(
    () => dialogsStore.changeCompany,
    () => {
        if (dialogsStore.changeCompany === true) {
            selectedCompany.value = userStore.currentCompany;
        }
    }
);

function closeDialog() {
    selectedCompany.value = null;
    companyName.value = '';
    dialogsStore.changeCompany = false;
}

function saveChooseCompany() {
    emit('companySelected', selectedCompany.value.companyId);
    closeDialog();
}

function chooseCompany(company) {
    selectedCompany.value = company;
}
</script>
