const configuration = {
    minAutocompleteLength: 2,
    onboarding: {
        maxCities: 3,
        maxNations: 3,
        maxMerits: 3,
        maxDefects: 3,
        personalitiesColumns: 2,
        maxStep: 2,
    },
    defaultDropAreaHeight: 200,
    pageLimitUploadTable: 10,
};

export default configuration;
