const en = {
    edit_profile: 'Edit profile',
    handle_cv: 'CV Management',
    competence_title: 'Do you want to enrich your skills?',
    competence_subtitle: 'enroll in our advanced courses',
    competence_button: 'Discover our courses',
    knowledge_title: 'Do you want to enrich your knowledge?',
    knowledge_subtitle: 'subscribe to our telegram channel',
    knowledge_button: 'Discover our webinars',
    choose_hobby: 'Enter your hobby',
    profile_photo: 'Profile photo',
    video_cv: 'Video CV',
    about_me: 'Something about me',
    other: 'Other',
    languages: 'Languages',
    hobby: 'Hobbies',
    social: 'Social',
    link: 'Link',
    certificates: 'Certificates',
    certifications: 'Certifications',
    questionnaires: 'Questionnaires',
    insert_certificate: 'Insert a certificate',
    insert_certification: 'Insert a certification',
    spoken_languages: 'Spoken languages',
    add_your_cv: 'Add your new CV',
    cv_uploaded: 'Uploaded resumes',
    select_language: 'Select a language',
    update_user_message: 'Profile updated successfully!',
    profile: 'Profile',
    account_setting: 'Account',
    add_video_cv: 'Add your CV video',
    upload_video: 'Upload video',
    add_link_video: 'Or insert link to CV video',
    view_all_text_privacy: 'View full text',
    open_to_work: 'Open to work',
    by_setting_the_mode_on:
        'By setting the ON mode you have access to the job search features. Other users will not know that you are Open to work',
    profile_settings: {
        in_remote: 'Remotely',
        offers_distance: 'Maximum distance of offers',
        phone_number: 'Phone number',
        connected_accounts: 'Connected accounts',
        other_settings: 'Other settings',
        show_opportunities: 'Show me opportunities',
        app_languages: 'Application language',
        others_cities_and_nations: 'Additional Cities and Nations',
        notification_preference: 'Notification preferences',
        privacy: 'Privacy',
        cookie_policy: 'Cookie Policy',
        privacy_policy: 'Privacy Policy',
        terms_and_conditions: 'Terms and conditions',
        legal_notes: 'Legal notes',
        delete_account: 'Delete account',
    },
    already_signup: 'Are you already registered?',
    sure_delete_me:
        'Are you sure you want to delete your account? The action is irreversible and all your data will be deleted',
};
export default en;
