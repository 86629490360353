const en = {
    openhr: {
        home: {
            title: "Search your suppliers' CV databases",
            content:
                'Open HR is the Wh tool that allows you to aggregate and view candidates from your supplier databases in one place.',
            button: 'Activate Open HR',
        },
        stepOne: {
            activation: 'Activation',
            title: 'Your company',
            subtitle: 'You are activating Open HR with the company',
            privacy_title: 'We protect the importance of your data',
            privacy_content: `OpenHR is a mutual sharing tool between suppliers and partners. You can share all or part of your CV database outside your organization. Before granting external visibility of your database you will need to approve the connection request and select the visibility parameters. You can share the entire database or a part of it (such as only the profiles you have discarded, only the junior profiles, etc.). The CVs you share externally are "managed" by you, therefore the candidate's contact information and other recognizable information such as name and surname will be obscured. With the "blind recruiting" option, when a company selects one of your profiles, your contact information will appear and you will directly take care of defining the collaboration conditions.`,
            privacy_label: 'I agree',
            info: 'Open HR is the Wh tool that allows you to aggregate and view candidates from your supplier databases in one place.',
        },
        connect: {
            filter_by: 'Filter by',
            active_connection: 'Active connections',
            deactivated: 'Deactivated',
            waiting: 'Requests awaiting approval',
        },
        connectDetail: {
            visibility_type: 'Visibility type',
            visibility_option_mutual: 'Mutual',
            visibility_option_mutual_text: 'Both of you can search your databases',
            visibility_option_internal_share:
                "You have access to the other company's database",
            visibility_option_internal_share_text:
                'You can search the {company_name} database',
            visibility_option_db_access: 'Grant access to your database',
            visibility_option_db_access_text: '{company_name} can search your database',
            profile_shared: 'Which of your profiles are you sharing?',
            seniority: 'Seniority',
            shared_saved_profiles: 'How many of your profiles are you sharing?',
            percentage_in_database: 'Equal to {percentage}% of your database.',
        },
        request_list: {
            request_to_approve: 'requests to approve',
            company_name: 'Company name',
            sector: 'Sector',
            visibility_type: 'Visibility type',
        },
        visibility_dialog: {
            type: 'Visibility type',
            content_reciprocal:
                "You are setting mutual visibility. {company_name} will be able to see your CV database and you will be able to see {company_name}'s.",
            content_active:
                'You are setting visibility On. You can search within the {company_name} database.',
            content_passive:
                'You are setting Passive visibility. {company_name} will be able to see your CV database.',
            content_none:
                "You're taking away visibility. {company_name} will not be able to see your CV database and you will not be able to see {company_name}'s. ",
            visibility_settings: 'Visibility settings',
            visibility_text:
                'Do you grant visibility of the entire database? We remind you that only the visibility of the macrodata of your database will be granted',
        },
        visibility_settings: {
            connection_type: 'Connection request visibility type',
            mutual: 'Reciprocal',
            mutual_explanation: 'You can both search your databases',
            shared: "You have access to the other company's database",
            shared_explanation: 'You can search the {company_name} database',
            db_access: 'Grant access to your database',
            db_access_explanation: '{company_name} can search your database',
        },
        no_connections: "We didn't find any companies that match your search",
        accepted: 'Active connection',
        none: 'Connect',
        revoked: 'Deactivated',
        to_be_accepted: 'Request pending',
        tooltip: {
            active: 'You can search the {company_name} database',
            none: 'No active connection',
            passive: '{company_name} can search your database',
            reciprocal: 'Both of you can search your databases',
        },
        visibility: {
            active: 'Active',
            none: 'None',
            passive: 'Passive',
            reciprocal: 'Reciprocal',
        },
        clickInfoMessage: 'click here to manage requests',
        see_details: 'See details',
        active: 'Active',
        passive: 'Passive',
        reciprocal: 'Reciprocal',
        refused: 'Refused',
        suspended: 'Suspended',
        error_accept_relation: 'Error requesting acceptance',
        error_refuse_relation: 'Error requesting refusal',
        connect_success: 'Request sent successfully',
        accept_relation_success: 'Request accepted successfully',
        refuse_relation_success: 'Request rejected successfully',
        no_request: 'We did not find any connection requests',
    },
    no_company_found:
        'No companies match your search. It may not exist or have OpenHr disabled.',
    open_hr_active_tooltip: "You can search the other company's CV database",
    open_hr_passive_tooltip: 'Grant access to your CV database',
    open_hr_reciprocal_tooltip: 'Both of you can search your CV databases',
};

export default en;
