<template>
    <v-dialog class="font" v-model="dialogsStore.termsCompany" persistent>
        <v-sheet :width="getWidth()" heigth="80vh" class="pa-6 rounded-xl">
            <v-row no-gutters justify="center" align-items="center">
                <v-col class="px-2">
                    <div>
                        <v-img
                            height="70"
                            width="70"
                            class="mx-auto"
                            src="@/assets/images/logo-color.svg"
                        ></v-img>
                        <!-- <div class="text-h6 font-weight-medium text-center">
                            {{ $t('welcome_to_wh') }}
                        </div>
                        <div class="text-subtitle-2 text-highlight mt-2 text-center">
                            {{ $t('simple_rules') }}
                        </div> -->
                        <div
                            class="modal-text-content mt-6"
                            style="height: 35vh; overflow-y: auto"
                            v-on:scroll.passive="checkScroll"
                        >
                            <div class="text-body-2" v-html="terms?.content" />
                        </div>
                        <div class="d-flex justify-center mt-2">
                            <v-btn icon color="white" @click="scrollTo" size="small">
                                <v-icon color="primary">mdi-chevron-down</v-icon>
                            </v-btn>
                        </div>
                        <div class="d-flex justify-center">
                            <v-btn
                                rounded="pill"
                                :disabled="!canAccept"
                                class="text-uppercase mt-6"
                                color="primary"
                                flat
                                @click="handleAcceptClick"
                            >
                                {{ $t('accept') }}
                            </v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-sheet>
    </v-dialog>
</template>

<script lang="ts" setup>
import { useAcceptTermAndCondition, useGetTerms } from '@/composables/user';
import { ref, watch } from 'vue';
import { checkViewType } from '@/composables/auth';
import { useDialogsStore } from '@/store/dialogs';
import { useI18n } from 'vue-i18n';
import { TermConditionType } from '@/graphql/types/graphql';
import { useDisplay } from 'vuetify';

const display = useDisplay();

function getWidth() {
    if (display.xs.value) {
        return 300;
    }
    if (display.sm.value) {
        return 500;
    }
    return 600;
}

const dialogsStore = useDialogsStore();
const canAccept = ref(false);
const i18n = useI18n();
const language = ref(i18n.locale.value == 'it' ? 'ita' : 'eng');
const terms = ref(null);
const loading = ref(false);

watch(i18n.locale, () => {
    language.value = i18n.locale.value == 'it' ? 'ita' : 'eng';
    getTerm();
});

const {
    mutate: acceptTerms,
    loading: loadingAccept,
    onDone: onDoneAccept,
} = useAcceptTermAndCondition(checkViewType);

function getTerm() {
    const {
        result,
        loading: loadingGet,
        onError,
    } = useGetTerms(language, TermConditionType.Company);
    watch(result, (data) => {
        if (data?.TermConditionLast) {
            terms.value = data?.TermConditionLast;
        }
    });

    watch(loadingGet, () => {
        loading.value = loadingGet.value;
    });
}

watch(
    () => dialogsStore.termsCompany,
    () => {
        if (dialogsStore.termsCompany) {
            getTerm();
        }
    }
);

function scrollTo() {
    const element = document.getElementsByClassName('modal-text-content')[0];
    if (element) {
        element.scrollTop = element.scrollHeight;
        canAccept.value = true;
    }
}

function checkScroll() {
    const element = document.getElementsByClassName('modal-text-content')[0];
    if (element && element.scrollHeight - element.clientHeight === element.scrollTop) {
        canAccept.value = true;
    }
}

async function handleAcceptClick() {
    await acceptTerms({
        termConditionId: '4a93e668-825d-4842-a478-ede80b9f748d',
    });
}

onDoneAccept(() => {
    dialogsStore.termsCompany = false;
});
</script>
