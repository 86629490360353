import { ONBOARDING_DATE_FORMAT, checkDateString, formatDate } from '@/plugins/moment';
import { Qualification, Skill, User, UserType } from '@/graphql/types/graphql';

import { Maybe } from 'graphql/jsutils/Maybe';
import { PersonalityType } from '@/composables/personalities';
import _ from 'lodash';
import configuration from '@/composables/config';
import { defineStore } from 'pinia';
import moment from 'moment';
import validator from 'validator';

export interface OnboardingState {
    email: string;
    password: string;
    passwordConfirm: string;
    newsletterCheck: boolean;
    name: string;
    surname: string;
    birthday: string;
    city: string;
    workFromHome: boolean;
    workFromMyCity: boolean;
    otherCity1: string;
    otherCity2: string;
    otherCity3: string;
    otherNation1: string;
    otherNation2: string;
    otherNation3: string;
    showWorkPreferenceOnProfile: boolean;
    qualification: Maybe<Qualification>;
    experience: number;
    showProfilePreferenceOnProfile: boolean;
    merits: string[];
    defects: string[];
    showMeritsPreferenceOnProfile: boolean;
    showDefectsPreferenceOnProfile: boolean;
    skills: Skill[];
    photo: string;
    opportunityLessThan1year: boolean;
    opportunityMoreThan1year: boolean;
    opportunityHiring: boolean;
    opportunityFreelance: boolean;
    files: File[];
    croppedFile: Maybe<File>;
    video: string;
    cvPresent: boolean;
    userType?: Maybe<string>;
}

export const useOnboardingStore = defineStore('onboarding', {
    state: (): OnboardingState => {
        return {
            email: '',
            password: '',
            passwordConfirm: '',
            newsletterCheck: false,
            name: '',
            surname: '',
            birthday: '',
            city: '',
            workFromHome: false,
            workFromMyCity: false,
            otherCity1: '',
            otherCity2: '',
            otherCity3: '',
            otherNation1: '',
            otherNation2: '',
            otherNation3: '',
            showWorkPreferenceOnProfile: false,
            qualification: null,
            experience: 0,
            showProfilePreferenceOnProfile: false,
            merits: [],
            defects: [],
            showMeritsPreferenceOnProfile: false,
            showDefectsPreferenceOnProfile: false,
            skills: [],
            photo: '',
            opportunityLessThan1year: false,
            opportunityMoreThan1year: false,
            opportunityHiring: false,
            opportunityFreelance: false,
            files: [],
            croppedFile: null,
            video: '',
            cvPresent: false,
            userType: null,
        };
    },
    actions: {
        syncUserData(user: Maybe<Partial<User>>) {
            this.name = user?.name ? user?.name : '';
            this.surname = user?.surname ? user?.surname : '';
            if (user?.birth_date != null) {
                this.birthday = moment(
                    new Date(parseInt(user?.birth_date ? user?.birth_date : ''))
                ).format(ONBOARDING_DATE_FORMAT);
            } else {
                this.birthday = '';
            }
            this.city = user?.city_residence ? user?.city_residence : '';
            this.workFromHome = user?.like_work_home == true;
            this.workFromMyCity = user?.like_work_my_city == true;
            this.otherCity1 = user?.like_work_other_city_1
                ? user?.like_work_other_city_1
                : '';
            this.otherCity2 = user?.like_work_other_city_2
                ? user?.like_work_other_city_2
                : '';
            this.otherCity3 = user?.like_work_other_city_3
                ? user?.like_work_other_city_3
                : '';
            this.otherNation1 = user?.like_work_other_state_1
                ? user?.like_work_other_state_1
                : '';
            this.otherNation2 = user?.like_work_other_state_2
                ? user?.like_work_other_state_2
                : '';
            this.otherNation3 = user?.like_work_other_state_3
                ? user?.like_work_other_state_3
                : '';
            this.showWorkPreferenceOnProfile = user?.like_show_on_profile == true;
            if (user?.qualifications != null) {
                this.qualification = user?.qualifications[0]?.qualification;
                this.experience = user?.qualifications[0]?.experienceInYears
                    ? user?.qualifications[0]?.experienceInYears
                    : 0;
            }
            this.showWorkPreferenceOnProfile =
                user?.show_merit_personality_on_profile == true;
            this.showMeritsPreferenceOnProfile =
                user?.show_merit_personality_on_profile == true;
            this.merits = _.compact(
                _.map(
                    _.filter(user?.personalities, {
                        section: PersonalityType.merit.toUpperCase(),
                    }),
                    (p) => (p?.id ? p?.id : '')
                )
            );
            this.showDefectsPreferenceOnProfile =
                user?.show_defect_personality_on_profile == true;
            this.defects = _.compact(
                _.map(
                    _.filter(user?.personalities, {
                        section: PersonalityType.defect.toUpperCase(),
                    }),
                    (p) => (p?.id ? p?.id : '')
                )
            );
            this.skills = user?.skills as Skill[];
            this.opportunityLessThan1year = user?.opportunity_less_than_1_year == true;
            this.opportunityMoreThan1year = user?.opportunity_more_than_1_year == true;
            this.opportunityHiring = user?.opportunity_hiring == true;
            this.opportunityFreelance = user?.opportunity_freelance == true;
            this.video = user?.curriculumVideo?.fileName
                ? user?.curriculumVideo?.fileName
                : '';
            this.cvPresent = user?.curriculum ? true : false;
            this.userType = user?.userType;
        },
        cvUploaded(uploaded: boolean) {
            this.cvPresent = uploaded;
        },
        increaseExperience() {
            if (this.experience < 10) {
                this.experience++;
            }
        },
        decreaseExperience() {
            if (this.experience > 0) {
                this.experience--;
            }
        },
        changeExperience(value: number) {
            this.experience = value;
        },
        toggleSelectedPersonality(selected: string, type: PersonalityType) {
            switch (type) {
                case PersonalityType.merit:
                    if (this.isPersonalitySelected(selected, type)) {
                        this.merits = _.xor(this.merits, [selected]);
                    } else if (this.merits.length < configuration.onboarding.maxMerits) {
                        this.merits = _.xor(this.merits, [selected]);
                    }
                    break;
                case PersonalityType.defect:
                    if (this.isPersonalitySelected(selected, type)) {
                        this.defects = _.xor(this.defects, [selected]);
                    } else if (
                        this.defects.length < configuration.onboarding.maxDefects
                    ) {
                        this.defects = _.xor(this.defects, [selected]);
                    }
                    break;
            }
        },
        isPersonalitySelected(id: string, type: PersonalityType): boolean {
            switch (type) {
                case PersonalityType.merit:
                    return _.indexOf(this.merits, id) >= 0;
                case PersonalityType.defect:
                    return _.indexOf(this.defects, id) >= 0;
            }
        },
        isSkillSelected(id: string): boolean {
            return this.skills
                .map((skill) => {
                    return skill.id;
                })
                .includes(id);
        },
        toggleSkillSelected(skill: Skill) {
            if (this.isSkillSelected(skill.id)) {
                _.remove(this.skills, { id: skill.id });
            } else {
                this.skills.push(skill);
            }
        },
        removeSKill(id: string) {
            if (this.isSkillSelected(id)) {
                _.remove(this.skills, { id: id });
            }
        },
        selectSkills(list: Skill[]) {
            this.skills = list;
        },
        resetSkills() {
            this.skills = [];
        },
    },
    getters: {
        citySelected: (state) =>
            !_.isEmpty(state.otherCity1) ||
            !_.isEmpty(state.otherCity2) ||
            !_.isEmpty(state.otherCity3),
        nationSelected: (state) =>
            !_.isEmpty(state.otherNation1) ||
            !_.isEmpty(state.otherNation2) ||
            !_.isEmpty(state.otherNation3),
        workFromHomeOrMyCity: (state) => state.workFromHome || state.workFromMyCity,
        workOptionSelected(): boolean {
            return this.citySelected || this.nationSelected || this.workFromHomeOrMyCity;
        },
        experienceString(): string {
            if (this.experience == 1) {
                return this.experience + ' Anno';
            }
            if (this.experience == 10) {
                return this.experience + ' Anni e più';
            }
            return this.experience + ' Anni';
        },
        isStepOneCompleted(): boolean {
            if (this.userType == UserType.Talent) {
                return (
                    !!this.name &&
                    !!this.surname &&
                    checkDateString(formatDate(this.birthday), ONBOARDING_DATE_FORMAT) &&
                    !!this.qualification &&
                    !!this.experience
                );
            }
            return (
                !!this.name && !!this.surname && !!this.qualification && !!this.experience
            );
        },
    },
});
