import { createI18n } from 'vue-i18n';
import en from './en/main';
import enAdmin from './en/admin';
import enAnalytics from './en/analytics';
import enCompanies from './en/companies';
import enErrors from './en/errors';
import enFolders from './en/folders';
import enHometour from './en/hometour';
import enJoboffer from './en/joboffer';
import enLabels from './en/labels';
import enOnboarding from './en/onboarding';
import enOpenHr from './en/openhr';
import enPassword from './en/password';
import enUser from './en/user';
import it from './it/main';
import itAdmin from './it/admin';
import itAnalytics from './it/analytics';
import itCompanies from './it/companies';
import itErrors from './it/errors';
import itFolders from './it/folders';
import itHometour from './it/hometour';
import itJoboffer from './it/joboffer';
import itLabels from './it/labels';
import itOnboarding from './it/onboarding';
import itOpenHr from './it/openhr';
import itPassword from './it/password';
import itUser from './it/user';

const ita = {
    ...it,
    ...itOnboarding,
    ...itErrors,
    ...itCompanies,
    ...itHometour,
    ...itJoboffer,
    ...itUser,
    ...itOpenHr,
    ...itAdmin,
    ...itPassword,
    ...itLabels,
    ...itFolders,
    ...itAnalytics,
};
const eng = {
    ...en,
    ...enOnboarding,
    ...enErrors,
    ...enCompanies,
    ...enHometour,
    ...enJoboffer,
    ...enUser,
    ...enOpenHr,
    ...enAdmin,
    ...enPassword,
    ...enLabels,
    ...enFolders,
    ...enAnalytics,
};

const messages = {
    it: ita,
    en: eng,
};

export default createI18n({
    locale: 'it', // set locale
    messages, // set locale messages
});
