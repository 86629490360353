import {
    CompanyCvState,
    CompanySearchCvOutput,
    CompanyUploadCvElaborationDetail,
    Folder,
    HiringType,
    JobAnnouncentContractDurantion,
    Label,
    LocationType,
    Maybe,
    Qualification,
    SearchCompanyCvOrigin,
    Skill,
} from '@/graphql/types/graphql';

import _ from 'lodash';
import { defineStore } from 'pinia';

export interface SearchTalentState {
    // qualification: Maybe<Qualification>;
    qualification: any;
    skills: Skill[];
    locationType: Maybe<LocationType>;
    city: Maybe<string>;
    hiringType: Maybe<HiringType>;
    cvState: Maybe<CompanyCvState>;
    contractDuration: Maybe<JobAnnouncentContractDurantion>;
    importantInformation: Maybe<string>;
    experienceYear: Maybe<string>;
    specialCategory: boolean;
    results: CompanySearchCvOutput[];
    selected: CompanySearchCvOutput[];
    origin: SearchCompanyCvOrigin;
    // talent: Maybe<CompanyUploadCvElaborationDetail>;
    talent: any;
    talentLogs: any[];
    orderBy: string;
    loadingSearchResult: boolean;
    loadingTalentDetail: boolean;
    hasPrevSearch: boolean;
    canEdit: boolean;
    keywords: string[];
    labels: Label[];
    folders: Folder[];
    counts: any;
    savedSearch: any;
}

export const useSearchTalentStore = defineStore('searchTalent', {
    state: (): SearchTalentState => {
        return {
            qualification: null,
            skills: [],
            locationType: null,
            city: null,
            hiringType: null,
            contractDuration: null,
            importantInformation: null,
            experienceYear: null,
            specialCategory: false,
            results: [],
            selected: [],
            origin: SearchCompanyCvOrigin.Internal,
            talent: null,
            talentLogs: [],
            loadingSearchResult: false,
            loadingTalentDetail: false,
            hasPrevSearch: false,
            orderBy: 'correspondence',
            cvState: CompanyCvState.Active,
            canEdit: false,
            keywords: [],
            labels: [],
            folders: [],
            counts: null,
            savedSearch: null,
        };
    },
    getters: {
        cvs: (state) => {
            return (excludeEmployee: boolean) => {
                if (excludeEmployee) {
                    return state.results.filter((cv: CompanySearchCvOutput) => {
                        const tags: Label[] = cv.tags ? (cv.tags as Label[]) : [];
                        return (
                            _.findIndex(tags, { name: 'Employee', isDefault: true }) == -1
                        );
                    });
                } else return state.results;
            };
        },
    },
});
