const it = {
    emailNotValid: 'email non valida',
    passwordNotValid: 'password non valida',
    passwordsNotMatched: 'le password non corrispondono',
    password_rules:
        'La password deve contenere almeno 6 caratteri e deve includere almeno un numero, un carattere speciale, una lettera maiuscola e una lettera minuscola.',
    userExist: 'La mail inserita risulta già in uso',
    socialLoginError: "Errore durante l'accesso con {provider}",
    existingSocialLoginError: "L'utente ha già utilizzato un altro social login",
    password_policy_not_available: 'Errore - Password policy non disponibile',
};
export default it;
