const it = {
    remaining_day: 'giorni rimasti',
    full_time: 'Full time',
    part_time: 'Part time',
    less_than_1_year: 'meno di 1 anno',
    more_than_1_year: 'più di 1 anno',
    freelance: 'Freelance',
    hiring: 'Assunzione',
    remote: 'Remoto',
    presence: 'Presenza',
    hybrid: 'Ibrido',
    apply: 'Candidati',
    min_ral: 'RAL minima',
    offers_distance: 'Distanza massima offerte',
    year: 'anno',
    max_ral: 'RAL massima',
    add_job_offer: 'Crea un nuovo annuncio',
    job_basic_info: 'Informazioni di base',
    job_information: 'Informazioni dell’annuncio',
    job_title: 'Titolo',
    job_typology: 'Tipologia',
    job_features: 'Caratteristiche opportunità',
    job_place: 'Luogo attività lavorativa',
    job_address: 'Indirizzo sede',
    create_job: 'crea il tuo annuncio',
    configure_start_job: 'Inizia la configurazione dell’annuncio',
    configure_new_job: 'Crea un nuovo annuncio',
    duplicate_job: 'Duplica un annuncio esistente',
    job_info: 'Informazioni annuncio',
    job_description: 'Descrizione annuncio',
    job_detail: 'Dettagli annuncio',
    job_add_info: 'Informazioni aggiuntive',
    job_chat_question: 'Vuoi attivare il servizio di chat?',
    job_interview_required_question: 'È necessario un colloquio con i candidati?',
    job_assessment_required_question: 'È necessario l’assessment?',
    job_time_limit_question: 'Entro quanto ti serve la competenza cercata?',
    job_transfer: 'Disponibile a trasferte',
    job_expire_date: 'Data di scadenza',
    job_consult: 'Consulta l’annuncio!',
    job_consult_info:
        'Puoi aggiungere altre informazioni o apportare modifiche in qualsiasi momento.',
    job_summary: 'Riepilogo annuncio',
    job_expiration: 'scadenza',
    job_help_title: 'Hai bisogno di aiuto per creare il tuo annuncio di lavoro?',
    job_help_text:
        'Ricevi assistenza pratica e consigli individuali da un nostro consulente esperto',
    ask_help: 'Chiedi aiuto',
    job_cover_image_horizontal: 'Immagine di copertina (orizzontale)',
    job_cover_image_info:
        "Questa immagine sarà visibile nel dettaglio dell'annuncio (il formato richiesto è orizzontale)",
    job_typology_contract: 'Tipologia di contratto',
    job_offer: 'Annuncio',
    no_job: 'Non ci sono offerte di lavoro in questo momento',
    job_offer_list: 'I tuoi annunci di lavoro',
    job_offer_applications: 'Elenco Candidature',
    immediately: 'Subito',
    max_2_months: 'Due mesi',
    max_6_months: 'Sei mesi',
    job_relocation: 'Disponibile per una relocation',
    job_change_role: 'Disponibile a cambio mansione/ruolo',
    job_status: 'Stato annuncio',
    draft: 'Bozza',
    public: 'Attivo',
    job_preview_image: 'Immagine per la card di anteprima (verticale)',
    job_preview_image_info:
        "Questa immagine sarà visibile nella card di anteprima dell'annuncio (il formato richiesto è verticale)",
    job_offer_saved_draft: "L'annuncio è stato salvato in bozza",
    job_visualizations: 'Visualizzazioni',
    job_applications: 'Candidature',
    no_job_applications: 'Non sono presenti candidature',
    top_trend: 'Top trend',
    no_job_offers: 'Non sono presenti offerte',
    ral_min_error: 'La ral minima deve essere diversa da zero',
    share_job_offer: 'Condividi annuncio',
    share_sms: 'SMS',
    share_linkedin: 'LinkedIn',
    share_facebook: 'Facebook',
    share_telegram: 'Telegram',
    share_email: 'Email',
    share_twitter: 'Twitter',
    share_wathsapp: 'Whatsapp',
    share_social: 'Condividi sui social',
    accepted: 'IN SELEZIONE',
    hired: 'ASSUNTO',
    refused: 'SCARTATO',
    generate_sharing_link: 'Genera link per condividere la pagina con le tue offerte',
    your_privacy_policy: 'Link della tua privacy policy',
    check_all_data_jobs:
        "Per pubblicare l'offerta di lavoro, assicurati di aver compilato tutti i campi. Modifica l'offerta.",
    invalid_privacyUrl: 'Url della privacy policy non valida',
    job_no_expire_date: 'Nessuna scadenza',
    percentage_correspondence: '% corrispondenza',
    percentage_correspondence_info:
        'La percentuale indica la corrispondenza del candidato confrontando la candidatura con i CV presenti nel database della tua azienda.',
    cv_upload_date: 'Data del CV',
    provenance: 'Provenienza',
    provenance_status: {
        all: '',
        user_hr: 'OpenHR',
        platform: 'WH',
        external: 'Esterna',
        internal: 'Dal tuo DB',
        unknown: 'Sconosciuta',
    },
    candidate_saved_by: 'Salvato da {count} aziende',
    hide_iframe: 'Nascondi iframe',
    show_iframe: 'Mostra iframe',
    copy_iframe: 'Copia iframe',
    iframe_copied: 'iframe copiato',
    share_job_announcement: "Condividi l'offerta di lavoro",
    back_to_announcement_list: 'Torna alla lista delle offerte di lavoro',
    signup_to_save_announcement:
        "Registrati per salvare l'offerta e candidarti in un secondo momento",
    applications_received_for_the_announcement: 'Candidature ricevute per l’annuncio',
    select_cv_db_source: 'Seleziona da quale database visualizzare i CVs',
    ral_info:
        "Per RAL si intende la Retribuzione Annua Lorda ed equivale all'importo che intendi offrire comprensivo di imposte, contributi e trattenute. Imposta il range indicativo (minimo e massimo) della RAL che intendi offrire (in migliaia €). Impostando il valore minimo e massimo uguale a zero la RAL non sarà mostrata tra le informazioni dell'annuncio.",
    your_db: 'Il tuo db',
    open_hr_db: 'database dei fornitori',
    referent: 'Referente',
};
export default it;
