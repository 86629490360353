import { Personality, Qualification } from '@/graphql/types/graphql';

import { PersonalityType } from '@/composables/personalities';
import _ from 'lodash';
import configuration from '@/composables/config';
import { defineStore } from 'pinia';

export interface PersonalitiesState {
    personalities: Personality[];
    splittedPersonalities: Personality[][];
}

export const usePersonalitiesStore = defineStore('personalities', {
    state: (): PersonalitiesState => {
        return {
            personalities: [],
            splittedPersonalities: [],
        };
    },
    actions: {
        split(type: PersonalityType, columns: number) {
            this.splittedPersonalities = _.chunk(
                this.personalities,
                this.personalities.length / columns
            );
        },
    },
});
