<template>
    <v-autocomplete
        v-model="model"
        :items="userStore.filteredCompany"
        hide-no-data
        underlined
        clearable
        hide-selected
        color="primary"
        class="no-padding-start"
        :label="$t('company')"
        :placeholder="$t('company')"
    ></v-autocomplete>
</template>

<script lang="ts" setup>
import { useUserStore } from '@/store/user';
import { computed, ref, defineEmits, withDefaults, defineProps } from 'vue';
import _ from 'lodash';

const emit = defineEmits(['select']);

let userStore = useUserStore();

interface UserCompanySelectorProps {
    value: any | null;
}

const props = withDefaults(defineProps<UserCompanySelectorProps>(), {
    value: null,
});

const model = computed({
    get() {
        return props.value?.companyName;
    },
    set(newValue) {
        const company = _.find(userStore.allCompanies, {
            companyName: newValue,
        }) as any;
        emit('select', company);
    },
});
</script>
