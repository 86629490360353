const en = {
    labels: 'Labels',
    my_labels: 'My labels',
    add_label: 'Add a new label',
    create_label_name_hint: 'Label name',
    create_label_description_hint: 'Label description',
    confirm_delete_label: 'Are you sure you want to delete the <b>{labelName}</b> label?',
    search_label: 'Search label',
    manage_labels: 'Manage labels',
    parent_label: 'Parent label',
};
export default en;
