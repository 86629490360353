import { CompanySearchCvOutput, Label, Scalars } from '@/graphql/types/graphql';

import { Maybe } from 'graphql/jsutils/Maybe';
import _ from 'lodash';
import { defineStore } from 'pinia';

export interface LabelsState {
    labels: Label[];
    labelFormModel: Label;
    loading: boolean;
    options: {
        presentInAll: string[];
        indeterminate: string[];
    };
}

export const useLabelsStore = defineStore('labels', {
    state: (): LabelsState => {
        return {
            labels: [],
            labelFormModel: { id: undefined, name: undefined, description: undefined },
            loading: false,
            options: {
                presentInAll: [],
                indeterminate: [],
            },
        };
    },
    getters: {
        islabelFormModelNameValid: (state) =>
            _.isString(state.labelFormModel.name) &&
            !_.isEmpty(state.labelFormModel.name),
        getLabelsCanBeParent: (state) => {
            return (exceptLabelId: Maybe<Scalars['ID']>) =>
                _.filter(state.labels, (label: Label) => {
                    return (
                        !label.isDefault &&
                        label.parentId == '' &&
                        label.id !== exceptLabelId
                    );
                });
        },
        getSelectable: (state) => {
            return _.filter(state.labels, (label: Label) => {
                return label.isDefault != true;
            });
        },
        sortLabelsForList: (state) => {
            const parents = _.sortBy(
                _.filter(state.labels, (label: Label) => {
                    return (
                        label.isDefault || label.parentId == '' || label.parentId == null
                    );
                }),
                'name'
            );
            const childsGroupedByParent = _.groupBy(
                _.filter(state.labels, (label: Label) => {
                    return (
                        !label.isDefault && label.parentId != null && label.parentId != ''
                    );
                }),
                'parentId'
            );
            let result: Label[] = [];
            _.forEach(parents, (parent) => {
                result.push(parent);
                result = [
                    ...result,
                    ..._.sortBy(
                        childsGroupedByParent[parent.id as string] as Label[],
                        'name'
                    ),
                ];
            });
            return result;
        },
    },
    actions: {
        resetLabelFormModel() {
            this.$state.labelFormModel = {
                id: undefined,
                name: undefined,
                description: undefined,
            };
        },
        setOptionsFromCvs(selectedCVs: CompanySearchCvOutput[]) {
            this.options.presentInAll = [];
            this.options.indeterminate = [];

            const allSelectedLabels = _.flatMapDepth(selectedCVs, (cv) => {
                return cv.tags;
            });
            const groups = _.groupBy(allSelectedLabels, 'id');
            if (!_.isEmpty(groups)) {
                _.forEach(this.$state.labels, (label) => {
                    const labelsList = groups[label.id as string];
                    if (_.isArray(labelsList)) {
                        if (labelsList.length == selectedCVs.length) {
                            this.options.presentInAll.push(label.id as string);
                        }
                        if (
                            labelsList.length > 0 &&
                            labelsList.length < selectedCVs.length
                        ) {
                            this.options.indeterminate.push(label.id as string);
                        }
                        // console.log(
                        //     label.id +
                        //         ' ' +
                        //         (groups[label.id as string] as Label[]).length
                        // );
                    }
                });
            }
            this.options.presentInAll = _.uniq(this.options.presentInAll);
            this.options.indeterminate = _.uniq(this.options.indeterminate);
        },
    },
});
