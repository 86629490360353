<template>
    <v-dialog
        v-if="surveyStore.questions.length > 0"
        class="font"
        v-model="dialogsStore.survey"
        persistent
    >
        <v-sheet width="600" heigth="80vh" class="pa-6 rounded-xl">
            <v-row no-gutters justify="space-between" align-items="justify">
                <div class="font-weight-bold">{{ surveyStore.title }}</div>
                <div class="text-capitalize cursor" @click="goNext()">
                    {{ $t('skip') }} >
                </div>
            </v-row>
            <v-row no-gutters class="mt-4 mb-6">
                <v-progress-linear
                    :model-value="getProgress()"
                    color="primary"
                    rounded
                ></v-progress-linear>
            </v-row>
            <v-row
                v-for="question in surveyStore.questions"
                :key="question.id"
                no-gutters
            >
                <v-col
                    v-if="currentQuestion == question.orderby"
                    class="font-weight-bold"
                >
                    <div>{{ parseQuestion(question.label) }}</div>
                    <v-row v-if="question.type == 'checkbox'" no-gutters class="mt-4">
                        <v-radio-group
                            :inline="question.proposals?.length > 5"
                            @update:modelValue="
                                handleCheckboxAnswer(question, $event);
                                disableNext = false;
                            "
                        >
                            <v-radio
                                v-for="proposal in question.proposals"
                                :key="proposal.id"
                                :label="proposal?.label"
                                :value="proposal?.label"
                            ></v-radio>
                        </v-radio-group>
                    </v-row>
                    <v-row v-if="question.type == 'range'" no-gutters class="mt-4">
                        <v-range-slider
                            :model-value="range"
                            step="5"
                            thumb-label="always"
                            :min="question.proposals[0].minvalue"
                            :max="question.proposals[0].maxvalue"
                            @update:modelValue="
                                handleRangeAnswer(question, $event);
                                disableNext = false;
                            "
                        ></v-range-slider>
                    </v-row>
                    <v-row no-gutters justify="end">
                        <v-btn
                            class="mr-2"
                            icon
                            size="small"
                            color="primary"
                            :disabled="disableNext"
                            @click="saveAndGoNext(question.id)"
                        >
                            <v-icon size="medium" color="secondary">
                                mdi-arrow-right
                            </v-icon>
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </v-sheet>
    </v-dialog>
</template>

<script lang="ts" setup>
import { useSaveSurveyAnswer } from '@/composables/survey';
import { SurveyQuestion } from '@/graphql/types/graphql';
import { useDialogsStore } from '@/store/dialogs';
import { useSurveyStore } from '@/store/survey';
import { useUserStore } from '@/store/user';
import _ from 'lodash';
import { ref } from 'vue';

const surveyStore = useSurveyStore();
const dialogsStore = useDialogsStore();
const userStore = useUserStore();
const currentQuestion = ref(1);
const disableNext = ref(true);

let answers = {};

const range = ref([20, 40]);

const { mutate: saveSurveyAnswer } = useSaveSurveyAnswer();

function getProgress() {
    if (surveyStore.questions.length > 0) {
        return (currentQuestion.value / surveyStore.questions.length) * 100;
    } else return 0;
}

function parseQuestion(text: string) {
    return text.replaceAll('USER', useUserStore().getName);
}

function goNext() {
    if (surveyStore.questions.length > 0) {
        if (currentQuestion.value < surveyStore.questions.length) {
            currentQuestion.value++;
            disableNext.value = true;
            initQuestion(surveyStore.questions[currentQuestion.value - 1]);
        } else {
            dialogsStore.survey = false;
            currentQuestion.value = 1;
            surveyStore.$reset();
        }
    }
}

function initQuestion(question: SurveyQuestion) {
    if (question.type == 'range') {
        range.value = [
            question.proposals[0]?.preselectedminvalue,
            question.proposals[0]?.preselectedmaxvalue,
        ];
        handleRangeAnswer(question, range.value);
        disableNext.value = false;
    }
    if (question.type == 'checkbox') {
        disableNext.value = true;
    }
}

function handleCheckboxAnswer(question, checkboxLabel) {
    const proposalId = _.find(question.proposals, { label: checkboxLabel }).id;
    const value = _.find(question.proposals, { label: checkboxLabel }).orderby;

    answers[question.id] = {
        questionId: question.id,
        proposalId: proposalId,
        userId: userStore.getUserId,
        value: value + '',
    };
    console.log(answers);
}

function handleRangeAnswer(question, rangeSelected) {
    range.value = rangeSelected;
    const proposalId = question.proposals[0].id;
    const value = range.value[0] + ';' + range.value[1];

    answers[question.id] = {
        questionId: question.id,
        proposalId: proposalId,
        userId: userStore.getUserId,
        value: value,
    };
    console.log(answers);
}

function saveAndGoNext(questionId) {
    saveSurveyAnswer(answers[questionId]);
    goNext();
}
</script>
