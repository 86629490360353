import i18n from '@/i18n';
import moment from 'moment';

export const ONBOARDING_DATE_FORMAT = 'DD/MM/YYYY';

export default function initMoment() {
    moment.locale(i18n.global.locale);
}

export function checkDateString(dateString: string, format: string): boolean {
    return moment(dateString, format).format(format) == dateString;
}

export function formatDateForApi(dateString: string, currentFormat: string): string {
    return moment(dateString, currentFormat).format('YYYY/MM/DD');
}

export function formatDate(dateString: string): string {
    return moment(dateString).format('DD/MM/YYYY');
}
