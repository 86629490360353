import { Businessarea, BusinessareaType } from '@/graphql/types/graphql';
import { defineStore } from 'pinia';

export interface BusinessareasState {
    businessareas: Businessarea[];
    filtered: string[];
    loading: boolean;
}

export const useBusinessareasStore = defineStore('businessareas', {
    state: (): BusinessareasState => {
        return {
            businessareas: [],
            filtered: [],
            loading: false,
        };
    },
    actions: {
        filter(searchText: string, type: BusinessareaType) {
            if (type) {
                this.filtered = this.businessareas
                    .filter((bs) => {
                        return bs.type == type;
                    })
                    .map((businessarea) => {
                        return businessarea.description;
                    })
                    .filter((des) => {
                        return (
                            (des || '')
                                .toLowerCase()
                                .indexOf((searchText || '').toLowerCase()) > -1
                        );
                    });
            } else {
                this.filtered = this.businessareas
                    .map((businessarea) => {
                        return businessarea.description;
                    })
                    .filter((des) => {
                        return (
                            (des || '')
                                .toLowerCase()
                                .indexOf((searchText || '').toLowerCase()) > -1
                        );
                    });
            }
        },
        resetFiltered(type: BusinessareaType) {
            if (type) {
                this.filtered = this.businessareas
                    .filter((bs) => {
                        return bs.type == type;
                    })
                    .map((el) => el?.description) as string[];
            } else {
                this.filtered = this.businessareas.map(
                    (el) => el?.description
                ) as string[];
            }
        },
    },
});
