import { EnvVars, env } from '@/utils/env';

export const loadClarity = () => {
    const clarityId = env()[EnvVars.VUE_APP_CLARITY_ID];
    const clarityScript = document.createElement('script');
    clarityScript.setAttribute('type', 'text/javascript');

    clarityScript.innerHTML = `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${clarityId}");`;

    document.head.appendChild(clarityScript);
};
