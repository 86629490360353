import { Policy } from '@/graphql/types/graphql';
import _ from 'lodash';
import { defineStore } from 'pinia';

export interface PasswordState {
    policies: Policy[];
    rules: string[];
}

export const usePasswordStore = defineStore('password', {
    state: (): PasswordState => {
        return {
            policies: [],
            rules: [],
        };
    },
    getters: {
        policiesReady: (state) => !_.isEmpty(state.policies),
    },
});
