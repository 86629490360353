import { defineStore } from 'pinia';

const MESSAGE_DELAY = 5000;

export interface MessageState {
    message: string;
}

export const useAppMessageStore = defineStore('appmessage', {
    state: (): MessageState => {
        return {
            message: '',
        };
    },
    actions: {
        setMessage(message: string) {
            this.message = message;
            setTimeout(() => {
                this.message = '';
            }, MESSAGE_DELAY);
        },
    },
});
