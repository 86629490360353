const en = {
    emailNotValid: 'invalid email',
    passwordNotValid: 'invalid password',
    passwordsNotMatched: 'passwords do not match',
    password_rules:
        'The password must contain at least 6 characters and must include at least one number, one special character, one uppercase letter and one lowercase letter.',
    userExist: 'The email entered is already in use',
    socialLoginError: 'Error logging in with {provider}',
    existingSocialLoginError: 'The user has already used another social login',
    password_policy_not_available: 'Error - Password policy not available',
};
export default en;
