import { defineStore } from 'pinia';

export interface DialogsState {
    editPassword: boolean;
    addCities: boolean;
    addNations: boolean;
    personalities: boolean;
    showJob: boolean;
    social: boolean;
    addCompany: boolean;
    changeCompany: boolean;
    terms: boolean;
    whoAreYou: boolean;
    hobby: boolean;
    certificates: boolean;
    certifications: boolean;
    skills: boolean;
    uploadImage: boolean;
    languages: boolean;
    uploadVideo: boolean;
    assignRole: boolean;
    uploadImageCover: boolean;
    uploadImagePreview: boolean;
    openhr_db_visibility: boolean;
    subscriptionPriceCalc: boolean;
    confirmAction: boolean;
    companyMeetingSchedule: boolean;
    draftCandidate: boolean;
    companyService: boolean;
    expertiseArea: boolean;
    filterCompanyJob: boolean;
    socialShare: boolean;
    jobInfo: boolean;
    termsCompany: boolean;
    inferencesMap: boolean;
    visibilityType: boolean;
    visibilityConfirm: boolean;
    shareUrlCompany: boolean;
    survey: boolean;
    refuseCandidate: boolean;
    saveToSavedSearch: boolean;
    hireOrRemove: boolean;
}

export const useDialogsStore = defineStore('dialgos', {
    state: (): DialogsState => {
        return {
            editPassword: false,
            addCities: false,
            addNations: false,
            personalities: false,
            showJob: false,
            social: false,
            addCompany: false,
            changeCompany: false,
            terms: false,
            whoAreYou: false,
            hobby: false,
            certificates: false,
            certifications: false,
            skills: false,
            uploadImage: false,
            languages: false,
            uploadVideo: false,
            assignRole: false,
            uploadImageCover: false,
            uploadImagePreview: false,
            openhr_db_visibility: false,
            subscriptionPriceCalc: false,
            confirmAction: false,
            companyMeetingSchedule: false,
            draftCandidate: false,
            companyService: false,
            expertiseArea: false,
            filterCompanyJob: false,
            socialShare: false,
            jobInfo: false,
            termsCompany: false,
            inferencesMap: false,
            visibilityType: false,
            visibilityConfirm: false,
            shareUrlCompany: false,
            survey: false,
            refuseCandidate: false,
            saveToSavedSearch: false,
            hireOrRemove: false,
        };
    },
});
