<template>
    <v-menu class="menu-custom">
        <template v-slot:activator="{ props }">
            <v-btn
                v-bind="props"
                elevation="0"
                v-if="isWhite()"
                variant="outlined"
                color="lightGray"
                rounded="pill"
            >
                <v-icon color="secondary" class="mr-2">mdi-menu</v-icon>
                <div
                    v-if="!isSmallDevice()"
                    class="font text-body-2 text-secondary font-weight-bold"
                >
                    {{ getCompanyName() }}
                </div>
            </v-btn>
            <v-btn
                v-bind="props"
                elevation="0"
                v-else
                color="white"
                rounded="pill"
                class="ml-4"
            >
                <v-icon color="secondary" class="mr-2">mdi-menu</v-icon>
                <div
                    class="font text-body-2 text-secondary font-weight-bold"
                    v-if="!isSmallDevice()"
                >
                    {{ getCompanyName() }}
                </div>
            </v-btn>
        </template>
        <v-list class="font" style="margin-left: -110px">
            <div v-if="getCompanyId()">
                <!-- <v-list-item>
                    <v-list-item-title class="text-body-2 text-secondary">
                        {{ $t('messages') }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title class="text-body-2 text-secondary">
                        {{ $t('notifications') }}
                    </v-list-item-title>
                </v-list-item>-->
                <v-list-item class="cursor" @click="goToRecentSearch">
                    <v-list-item-title class="text-body-2 text-secondary">
                        {{ $t('recent_searches') }}
                    </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item
                    class="cursor"
                    v-if="userStore.isAdminInCurrentCompany"
                    @click="manageCompany"
                >
                    <v-list-item-title
                        class="text-body-2 text-secondary font-weight-bold"
                    >
                        {{ $t('manage_company') }}
                    </v-list-item-title>
                </v-list-item>
                <v-divider v-if="userStore.isAdminInCurrentCompany"></v-divider>
                <v-list-item class="cursor" @click="goToDashboard">
                    <v-list-item-title class="text-body-2 text-secondary">
                        Dashboard
                    </v-list-item-title>
                </v-list-item>
                <v-list-item
                    class="cursor"
                    v-if="userStore.isAdminInCurrentCompany"
                    @click="goToEditCompanyProfile"
                >
                    <v-list-item-title class="text-body-2 text-secondary">
                        {{ $t('company_profile') }}
                    </v-list-item-title>
                </v-list-item>
            </div>
            <v-list-item @click="addCompany" class="cursor">
                <v-list-item-title class="text-body-2 text-secondary">
                    {{ $t('add_company') }}
                </v-list-item-title>
            </v-list-item>
            <v-list-item
                class="cursor"
                v-if="userStore.allCompanies.length > 1"
                @click="changeCurrentCompany"
            >
                <v-list-item-title class="text-body-2 text-secondary">
                    {{ $t('change_company') }}
                </v-list-item-title>
            </v-list-item>
            <v-list-item @click="openHr" class="cursor">
                <v-list-item-title class="text-body-2 text-secondary">
                    {{ $t('open_hr') }}
                </v-list-item-title>
            </v-list-item>
            <!-- <v-divider></v-divider>
            <v-list-item>
                <v-list-item-title class="text-body-2 text-secondary">
                    {{ $t('company_settings') }}
                </v-list-item-title>
            </v-list-item> -->
            <!-- <v-divider></v-divider>
            <v-list-item @click="goToSub">
                <v-list-item-title class="text-body-2 text-secondary">
                    {{ $t('subscriptions') }}
                </v-list-item-title>
            </v-list-item> -->
        </v-list>
    </v-menu>
</template>

<script lang="ts" setup>
import { RoutesNames } from '@/router/routesNames';
import { useRouter } from 'vue-router';
import { Company } from '@/graphql/types/graphql';
import { defineProps, withDefaults, defineEmits } from 'vue';
import { useUserStore } from '@/store/user';
import { useDialogsStore } from '@/store/dialogs';
import { useI18n } from 'vue-i18n';
import { useChangeCurrentAppVisualizzation } from '@/composables/user';
import { CurrentAppVisualizationType } from '@/graphql/types/graphql';
import { isSmallDevice } from '@/utils/helpers';
import { EnvVars, getEnv } from '@/utils/env';

const { t } = useI18n();
const userStore = useUserStore();
const dialogsStore = useDialogsStore();

const {
    mutate: changeCurrentAppVisualizzation,
    loading: loadingUpdate,
    onDone: onDonechangeCurrentAppVisualizzation,
} = useChangeCurrentAppVisualizzation();

const emit = defineEmits(['onOpenDialogAddCompany']);

const router = useRouter();

interface CompanyMenuProps {
    company: Company;
    theme: string;
}

const props = withDefaults(defineProps<CompanyMenuProps>(), {
    company: null,
    theme: 'white',
});

function goToEditCompanyProfile() {
    router.push({
        name: RoutesNames.edit_company_profile,
    });
}

function getCompanyName() {
    return props.company && props.company.companyName ? props.company.companyName : '';
}

function getCompanyId() {
    return props.company ? props.company.companyId : null;
}

function changeCurrentCompany() {
    dialogsStore.changeCompany = true;
}

function addCompany() {
    dialogsStore.addCompany = true;
    emit('onOpenDialogAddCompany');
}

function isWhite() {
    return props.theme === 'white';
}

function actionViewType() {
    return userStore.viewTypeUser ? t('view_type_company') : t('view_type_user');
}

async function changeViewType() {
    let companyId = getCompanyId();

    if (!companyId) {
        const allCompanies = userStore.allCompanies;
        if (allCompanies && allCompanies.length > 0) {
            companyId = allCompanies[0].companyId;
        }
    }

    const payload: any = {
        type: userStore.viewTypeUser
            ? CurrentAppVisualizationType.Company
            : CurrentAppVisualizationType.User,
    };

    if (payload.type === CurrentAppVisualizationType.Company) {
        payload.companyId = companyId;
    }

    await changeCurrentAppVisualizzation(payload);
}

onDonechangeCurrentAppVisualizzation(() => {
    router.replace({ name: RoutesNames.company_dashboard });
});

function manageCompany() {
    router.push({ name: RoutesNames.manage_company });
}

function openHr() {
    router.push({ name: RoutesNames.open_hr });
}

function goToRecentSearch() {
    router.push({ name: RoutesNames.company_dashboard_talent_search_history });
}

function goToDashboard() {
    router.push({ name: RoutesNames.company_dashboard });
}
</script>
<style scoped>
.v-list-item.v-theme--lightTheme.v-list-item--density-default.v-list-item--one-line.v-list-item--variant-text.cursor:hover {
    background-color: #efefef;
}
</style>
