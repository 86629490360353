export enum RoutesNames {
    root = 'root',
    dashboard = 'dashboard',
    admin_dashboard = 'admin_dashboard',
    search = 'search',
    jobs = 'jobs',
    jobDetail = 'jobDetail',
    matches = 'matches',
    learning = 'learning',
    onboarding_start = 'onboarding_start',
    onboarding_setPassword = 'onboarding_setPassword',
    onboarding_terms = 'onboarding_terms',
    onboarding_whoareyou = 'onboarding_whoareyou',
    onboarding_name = 'onboarding_name',
    onboarding_birthday = 'onboarding_birthday',
    onboarding_city = 'onboarding_city',
    onboarding_addcity = 'onboarding_addcity',
    onboarding_where_work = 'onboarding_where_work',
    onboarding_qualification = 'onboarding_qualification',
    onboarding_personality_merits = 'onboarding_personality_merits',
    onboarding_personality_defects = 'onboarding_personality_defects',
    onboarding_step_1 = 'onboarding_step_1',
    onboarding_step_2 = 'onboarding_step_2',
    company_dashboard_jobs_create = 'company_dashboard_jobs_create',
    company_dashboard_jobs_create_step_1 = 'company_dashboard_jobs_create_step_1',
    company_dashboard_jobs_create_step_2 = 'company_dashboard_jobs_create_step_2',
    company_dashboard_jobs_create_step_3 = 'company_dashboard_jobs_create_step_3',
    company_dashboard_jobs_create_step_4 = 'company_dashboard_jobs_create_step_4',
    company_dashboard_jobs_view = 'company_dashboard_jobs_view',
    edit_company_profile = 'edit_company_profile',
    user_profile = 'user_profile',
    edit_user_profile = 'edit_user_profile',
    company_dashboard = 'company_dashboard',
    management_dashboard = 'management_dashboard',
    edit_user_cv = 'edit_user_cv',
    company_dashboard_talent = 'company_dashboard_talent',
    company_dashboard_jobs = 'company_dashboard_jobs',
    company_dashboard_job_list = 'company_dashboard_job_list',
    upload_cv = 'upload_cv',
    manage_company = 'manage_company',
    upload_cv_detail = 'upload_cv_detail',
    company_dashboard_talent_search = 'company_dashboard_talent_search',
    company_dashboard_talent_search_detail = 'company_dashboard_talent_search_detail',
    company_dashboard_talent_search_history = 'company_dashboard_talent_search_history',
    privacy = 'privacy',
    personalize_privacy = 'personalize_privacy',
    open_hr = 'open_hr',
    open_hr_step_one = 'open_hr_step_one',
    open_hr_step_two = 'open_hr_step_two',
    open_hr_connect = 'open_hr_connect',
    subscription = 'subscription',
    confirmEmail = 'confirmEmail',
    userconfirm = 'userconfirm',
    resetPassword = 'resetPassword',
    signIn = 'signIn',
    signup_talent = 'signup_talent',
    company_dashboard_jobs_update = 'company_dashboard_jobs_update',
    company_dashboard_teams = 'company_dashboard_teams',
    company_dashboard_teams_create = 'company_dashboard_teams_create',
    company_dashboard_teams_create_2 = 'company_dashboard_teams_create_2',
    company_dashboard_teams_create_3 = 'company_dashboard_teams_create_3',
    team_list_detail = 'team_list_detail',
    company_job_list = 'company_job_list',
    company_job_candidate = 'company_job_candidate',
    company_terms = 'company_terms',
    open_hr_company_detail = 'open_hr_company_detail',
    openhr_requests_list = 'openhr_requests_list',
    company_dashboard_jobs_applications = 'company_dashboard_jobs_applications',
    privacy_cookie = 'privacy_cookie',
    terms_conditions = 'terms_conditions',
    terms_services = 'terms_services',
    api_key_documentation = 'api_key_documentation',
    help = 'help',
    profile_settings = 'profile_settings',
    not_found = 'not_found',
    manteinance = 'manteinance',
    talent = 'talent',
    iframe_job_list = 'iframe_job_list',
    iframe_job_detail = 'iframe_job_detail',
    manage_labels = 'manage_labels',
    manage_folders = 'manage_folders',
    employee = 'employee',
    employee_details = 'employee_details',
    managment_upload_cv = 'managment_upload_cv',
    managment_upload_cv_details = 'managment_upload_cv_details',
    jobs_applications = 'jobs_applications',
    jobs_top_trends = 'jobs_top_trends',
    analytics = 'analytics',
}
