import { useDisplay } from 'vuetify';

export const formatMoney = (value: number) => {
    const options = {
        significantDigits: 2,
        thousandsSeparator: '.',
        decimalSeparator: ',',
        symbol: '€',
    };

    if (typeof value !== 'number') value = 0.0;

    value = Number(value.toFixed(options.significantDigits));

    const [currency, decimal] = `${value}`.split('.');

    return `${currency.replace(/\B(?=(\d{3})+(?!\d))/g, options.thousandsSeparator)} 
        ${options.symbol}`;
};

export const isSmallDevice = () => {
    const display = useDisplay();
    return display.xs.value || display.sm.value;
};

export const capitalize = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};

export const isEuVatNumber = (text: string) => {
    // ex. IT12345678912
    const isValidRegex =
        /^((AT)(U\d{8})|(BE)(0\d{9})|(BG)(\d{9,10})|(CY)(\d{8}[LX])|(CZ)(\d{8,10})|(DE)(\d{9})|(DK)(\d{8})|(EE)(\d{9})|(EL|GR|CHE)(\d{9})|(ES)([\dA-Z]\d{7}[\dA-Z])|(FI)(\d{8})|(FR)([\dA-Z]{2}\d{9})|(HU)(\d{8})|(IE)(\d{7}[A-Z]{2})|(IT)(\d{11})|(LT)(\d{9}|\d{12})|(LU)(\d{8})|(LV)(\d{11})|(MT)(\d{8})|(NL)(\d{9}(B\d{2}|BO2))|(PL)(\d{10})|(PT)(\d{9})|(RO)(\d{2,10})|(SE)(\d{12})|(SI)(\d{8})|(SK)(\d{10}))$/gi;

    return isValidRegex.test(text);
};

export const isValidUrl = (text: string) => {
    const isValidRegex =
        /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

    return isValidRegex.test(text);
};

export const isValidEmail = (value: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(value);
};

export const saveOnLocalStorage = (data: any, key: string) => {
    try {
        if (data) {
            localStorage.setItem(key, JSON.stringify(data));
        } else {
            localStorage.removeItem(key);
        }
        return true;
    } catch (error) {
        console.log('error ', error);
        return false;
    }
};

export const getFromLocalStorage = (key: string) => {
    try {
        return localStorage.getItem(key);
    } catch (error) {
        console.log('error ', error);
        return false;
    }
};

export const isString = (element: any) => {
    return typeof element === 'string';
};

export const containsString = (array: any) => {
    return array.every(isString);
};

export function convertCamelToSnake(string: string) {
    return string.replace(/([a-zA-Z])(?=[A-Z])/g, '$1_').toLowerCase();
}

export function languageTranslator(languageId: string) {
    switch (languageId) {
        case 'ita':
            return 'it';
        case 'eng':
            return 'en';
        default:
            return 'it';
    }
}

export function getLanguageId(language: string) {
    switch (language) {
        case 'it':
            return 'ita';
        case 'en':
            return 'eng';
        default:
            return 'ita';
    }
}
