const en = {
    remaining_day: 'days left',
    full_time: 'Full time',
    part_time: 'Part time',
    less_than_1_year: 'Less than 1 year',
    more_than_1_year: 'More than 1 year',
    freelance: 'Freelance',
    hiring: 'Assumption',
    remote: 'Remote',
    presence: 'Presence',
    hybrid: 'Hybrid',
    apply: 'Apply',
    min_ral: 'Minimum RAL',
    offers_distance: 'Maximum distance of offers',
    year: 'year',
    max_ral: 'maximum RAL',
    add_job_offer: 'Create a new advert',
    job_basic_info: 'Basic information',
    job_information: 'Advertisement information',
    job_title: 'Title',
    job_typology: 'Typology',
    job_features: 'Opportunity Features',
    job_place: 'Place of work activity',
    job_address: 'Location address',
    create_job: 'Create your job',
    configure_start_job: 'Start configuring the ad',
    configure_new_job: 'Create a new job',
    duplicate_job: 'Duplicate an existing job',
    job_info: 'Job information',
    job_description: 'Ad description',
    job_detail: 'Advertisement details',
    job_add_info: 'Additional information',
    job_chat_question: 'Do you want to activate the chat service?',
    job_interview_required_question: 'Is an interview with candidates necessary?',
    job_assessment_required_question: 'Is assessment required?',
    job_time_limit_question: 'How long before you need the skill you are looking for?',
    job_transfer: 'Available to travel',
    job_expire_date: 'Expire date',
    job_consult: 'Consult the advert!',
    job_consult_info: 'You can add more information or make changes at any time.',
    job_summary: 'Job summary',
    job_expiration: 'expiration',
    job_help_title: 'Do you need help creating your job advert?',
    job_help_text:
        'Receive practical assistance and individual advice from one of our expert consultants',
    ask_help: 'Ask for help',
    job_cover_image_horizontal: 'Cover image (horizontal)',
    job_cover_image_info:
        'This image will be visible in the ad detail (the required format is landscape)',
    job_typology_contract: 'Contract type',
    job_offer: 'Advertisement',
    no_job: 'There are no job offers at this time',
    job_offer_list: 'Your job adverts',
    job_offer_applications: 'List of Applications',
    immediately: 'Immediately',
    max_2_months: 'Two months',
    max_6_months: 'Six months',
    job_relocation: 'Available for relocation',
    job_change_role: 'Available to change job/role',
    job_status: 'Advertisement status',
    draft: 'Draft',
    public: 'Active',
    job_preview_image: 'Preview card image (vertical)',
    job_preview_image_info:
        'This image will be visible in the ad preview card (the required format is portrait)',
    job_offer_saved_draft: 'The advertisement has been saved in draft',
    job_visualizations: 'Views',
    job_applications: 'Applications',
    no_job_applications: 'There are no applications',
    top_trend: 'Top trend',
    no_job_offers: 'There are no offers',
    ral_min_error: 'The minimum ral must be non-zero',
    share_job_offer: 'Share job offer',
    share_sms: 'SMS',
    share_linkedin: 'LinkedIn',
    share_facebook: 'Facebook',
    share_telegram: 'Telegram',
    share_email: 'Email',
    share_twitter: 'Twitter',
    share_wathsapp: 'Whatsapp',
    share_social: 'Social Share',
    accepted: 'IN SELECTION',
    hired: 'HIRED',
    refused: 'REJECTED',
    generate_sharing_link: 'Generate link to share the page with your offers',
    your_privacy_policy: 'Link to your privacy policy',
    check_all_data_jobs:
        'To publish the job offer, make sure you have filled in all the fields. Edit the offer.',
    invalid_privacyUrl: 'Invalid privacy policy URL',
    job_no_expire_date: 'No expire',
    percentage_correspondence: '% correspondence',
    percentage_correspondence_info:
        "The percentage indicates the candidate's match by comparing the application with the CVs in your company database.",
    cv_upload_date: 'CV date',
    provenance: 'Provenance',
    provenance_status: {
        all: '',
        user_hr: 'OpenHR',
        platform: 'WH',
        external: 'External',
        internal: 'Your DB',
        unknown: 'Unknown',
    },
    candidate_saved_by: 'Saved by {count} companies',
    hide_iframe: 'Hide iframe',
    show_iframe: 'Show iframe',
    copy_iframe: 'Copy iframe',
    iframe_copied: 'iframe copied',
    share_job_announcement: 'Share the job offer',
    back_to_announcement_list: 'Back to job posting list',
    signup_to_save_announcement: 'Register to save the offer and apply later',
    applications_received_for_the_announcement:
        'Applications received for the announcement',
    select_cv_db_source: 'Select which database to view CVs from',
    ral_info:
        'RAL means the Gross Annual Salary and is equivalent to the amount you intend to offer including taxes, contributions and withholdings. Set the indicative range (minimum and maximum) of the RAL you intend to offer (in thousands of €). By setting the minimum and maximum value equal to zero, the RAL will not be shown in the announcement information.',
    your_db: 'Your db',
    open_hr_db: 'supplier database',
    referent: 'Referent',
};
export default en;
