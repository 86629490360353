<template>
    <div>
        <div
            style="height: 75px"
            :class="`d-flex align-center justify-space-between ${
                display.xs.value ? 'px-4' : 'px-16'
            }`"
        >
            <div class="cursor d-flex" @click="goToHome">
                <v-img
                    :height="logoSize"
                    :width="logoSize"
                    class="my-auto"
                    :src="require(`@/assets/images/${logo}`)"
                ></v-img>
                <v-btn
                    v-if="
                        (route.name == RoutesNames.signIn ||
                            route.name == RoutesNames.onboarding_start ||
                            route.name == RoutesNames.signup_talent) &&
                        !display.xs.value
                    "
                    class="font ml-6 my-auto text-lowercase"
                    rounded="pill"
                    variant="flat"
                    color="grey-lighten-4"
                    @click="goToWh()"
                >
                    {{ $t('find_out_more') }}
                </v-btn>
            </div>
            <div class="d-flex">
                <language-menu :theme="getThemeColor()" :showTitle="!display.xs.value" />
                <v-btn
                    class="font ml-4"
                    rounded="pill"
                    variant="flat"
                    color="grey-lighten-4"
                    @click="handleButtonClick"
                >
                    {{
                        userStore.isLoggedIn
                            ? $t('exit')
                            : route.name == RoutesNames.signIn
                            ? $t('sign_up')
                            : $t('sign_in')
                    }}
                </v-btn>
            </div>
        </div>
        <v-divider />
        <who-are-you-dialog />
    </div>
</template>

<script lang="ts" setup>
import { signoutUser } from '@/composables/auth';
import { RoutesNames } from '@/router/routesNames';
import { useUserStore } from '@/store/user';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import WhoAreYouDialog from '../onboarding/WhoAreYouDialog.vue';
import LanguageMenu from './LanguageMenu.vue';
import { isSmallDevice } from '@/utils/helpers';
import { useDisplay } from 'vuetify';
import { checkViewType } from '@/composables/auth';

const route = useRoute();
const router = useRouter();

const userStore = useUserStore();
const display = useDisplay();

const logo = ref('logo-color.svg');
const logoSize = ref('40');

if (isSmallDevice()) {
    logoSize.value = '40';
} else {
    if (
        route.name == RoutesNames.onboarding_step_1 ||
        route.name == RoutesNames.onboarding_step_2
    ) {
        logo.value = 'logo-color.svg';
        logoSize.value = '60';
    } else {
        logo.value = 'logo-color.svg';
        logoSize.value = '60';
    }
}

function handleButtonClick() {
    if (userStore.isLoggedIn) {
        signoutUser();
    } else {
        if (route.name == RoutesNames.signIn) {
            router.push({ name: RoutesNames.onboarding_start });
        } else {
            router.push({ name: RoutesNames.signIn });
        }
    }
}

function getThemeColor() {
    return 'white';
    // return route.name !== RoutesNames.onboarding_start ? 'white' : 'transparent';
}

function goToHome() {
    // router.push({ name: RoutesNames.onboarding_start });
    checkViewType();
}

function goToWh() {
    // router.push({ name: RoutesNames.onboarding_start });
    window.location.href = 'https://www.whp.ai/';
}
</script>
