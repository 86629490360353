import { defineStore } from 'pinia';

export interface StringsOptionsState {
    strings1: string[];
    loadingStrings1: boolean;
    strings2: string[];
    loadingStrings2: boolean;
    strings3: string[];
    loadingStrings3: boolean;
}

export const useStringsOptionsStore = defineStore('stringOptions', {
    state: (): StringsOptionsState => {
        return {
            strings1: [],
            loadingStrings1: false,
            strings2: [],
            loadingStrings2: false,
            strings3: [],
            loadingStrings3: false,
        };
    },
    actions: {
        setStrings(strings: string[], index: number) {
            switch (index) {
                case 0:
                case 1:
                    this.strings1 = strings;
                    break;
                case 2:
                    this.strings2 = strings;
                    break;
                case 3:
                    this.strings3 = strings;
                    break;
            }
        },
        setLoadingStrings(loading: boolean, index: number) {
            switch (index) {
                case 0:
                case 1:
                    this.loadingStrings1 = loading;
                    break;
                case 2:
                    this.loadingStrings2 = loading;
                    break;
                case 3:
                    this.loadingStrings3 = loading;
                    break;
            }
        },
    },
});
