import {
    LinkExternalMediaDocument,
    UploadFileDocument,
    RemoveMediaDocument,
} from '@/graphql/types/graphql';
import mutation, { uploadMutation } from '@/graphql/mutation';
import { getUser } from '@/composables/user';
import { useErrorStore } from '@/store/error';

export function useUploadFile() {
    const { mutate, loading, onDone, onError } = uploadMutation(UploadFileDocument);

    onError((result) => {
        console.error(result.message);
    });

    return { mutate, loading, onDone };
}

export function useLinkExternalMedia() {
    const { mutate, loading, onDone, onError } = uploadMutation(
        LinkExternalMediaDocument
    );

    onError((result) => {
        console.error(result.message);
    });

    return { mutate, loading, onDone };
}

export function useRemoveMedia(disableSyncOnboardingStore = false) {
    const { mutate, loading, onDone, onError } = mutation(RemoveMediaDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });
    onDone((res) => {
        getUser(null, disableSyncOnboardingStore);
    });

    return { mutate, loading, onDone };
}
