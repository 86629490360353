import {
    MediaTypes,
    UploadFileInput,
    Maybe,
    Media,
    EntityType,
} from '@/graphql/types/graphql';
import { useLinkExternalMedia, useUploadFile } from '@/composables/upload';
import { defineStore } from 'pinia';

export interface UploadsState {
    photo: Maybe<UploadFileInput>;
    pdf: Maybe<UploadFileInput>;
    uuids: string[];
    uploadedMediaPhoto: Media[];
    base64: string;
    pdfBase64: string;
    pdfUuid: string;
    videoFile: Maybe<UploadFileInput>;
    uploadedVideo: Maybe<Media>;
    videoURL: Maybe<string>;
    videoUuid: string;
    uploadedLinkVideo: Maybe<Media>;
    uploadedCV: Maybe<Media>;
}

export const useUploadsStore = defineStore('uploads', {
    state: (): UploadsState => {
        return {
            photo: null,
            pdf: null,
            uuids: [],
            base64: '',
            pdfBase64: '',
            pdfUuid: '',
            videoFile: null,
            videoURL: null,
            videoUuid: '',
            uploadedMediaPhoto: [],
            uploadedVideo: null,
            uploadedLinkVideo: null,
            uploadedCV: null,
        };
    },
    actions: {
        async uploadPhoto(callback: any) {
            const { mutate: uploadFile, onDone: onDoneUploadFile } = useUploadFile();

            onDoneUploadFile((result) => {
                this.uuids.push(result.data?.uploadFile?.id as string);
                if (result.data?.uploadFile) {
                    this.uploadedMediaPhoto.push(result.data?.uploadFile);
                }
                this.photo = null;
                if (callback) {
                    callback();
                }
            });

            if (this.photo != null) {
                await uploadFile({
                    input: this.photo,
                });
            }
        },
        async uploadPDF(callback: any) {
            const { mutate: uploadFile, onDone: onDoneUploadFile } = useUploadFile();

            onDoneUploadFile((result) => {
                if (result.data?.uploadFile) {
                    this.uploadedCV = result.data?.uploadFile;
                }
                this.pdfUuid = result.data?.uploadFile?.id as string;
                this.pdf = null;
                if (callback) {
                    callback();
                }
            });

            if (this.pdf != null) {
                await uploadFile({
                    input: this.pdf,
                });
            }
        },
        async uploadVideo(callback: any) {
            const { mutate: uploadFile, onDone: onDoneUploadFile } = useUploadFile();

            onDoneUploadFile((result) => {
                this.videoUuid = result.data?.uploadFile?.id as string;
                if (result.data?.uploadFile) {
                    this.uploadedVideo = result.data.uploadFile;
                }
                this.videoFile = null;
                if (callback) {
                    callback();
                }
            });

            if (this.videoFile != null) {
                await uploadFile({
                    input: this.videoFile,
                });
            }
        },
        async addVideoUrl(owner: string, ownerType: EntityType, callback: any) {
            const { mutate: linkExternalMedia, onDone } = useLinkExternalMedia();

            onDone((result) => {
                this.videoUuid = result.data?.linkExternalMedia?.id as string;
                if (result.data?.linkExternalMedia) {
                    this.uploadedLinkVideo = result.data.linkExternalMedia;
                }
                this.videoURL = null;
                if (callback) {
                    callback();
                }
            });

            if (this.videoURL != null) {
                await linkExternalMedia({
                    input: {
                        destination: MediaTypes.Curriculumvideo,
                        fileURL: this.videoURL,
                        owner: owner,
                        ownerType: ownerType,
                    },
                });
            }
        },
    },
});
