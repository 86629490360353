const it = {
    openhr: {
        home: {
            title: 'Cerca tra i database di CV dei tuoi fornitori',
            content:
                'Open HR è lo strumento di Wh che ti permette di aggregare e visualizzare i candidati dei database dei tuoi fornitori in un unico luogo.',
            button: 'Attiva Open HR',
        },
        stepOne: {
            activation: 'Attivazione',
            title: 'La tua azienda',
            subtitle: 'Stai attivando Open HR con l’azienda',
            privacy_title: 'Tuteliamo l’importanza dei tuoi dati',
            privacy_content:
                'OpenHR è uno strumento di condivisione reciproca tra fornitori e partner. Puoi condividere all’esterno della tua organizzazione tutto o parte del tuo database di CV. Prima di concedere la visibilità del tuo database all’esterno dovrai approvare la richiesta di collegamento e selezionare i parametri di visibilità. Puoi condividere l’intero database oppure una parte di esso (come per esempio solo i profili che hai scartato, solo i profili junior, ecc.). I CV che condividi all’esterno risultano “gestiti” da te, pertanto saranno oscurate le informazioni di contatto del candidato e altre informazioni di riconoscibilità come il nome e il cognome. Con l’opzione “blind recruiting”, quando un azienda seleziona uno dei tuoi profili, compariranno le tue informazioni di contatto e ti occuperai direttamente te della definizione delle condizioni di collaborazione.',
            privacy_label: 'Acconsento',
            info: 'Open HR è lo strumento di Wh che ti permette di aggregare e visualizzare i candidati dei database dei tuoi fornitori in un unico luogo.',
        },
        connect: {
            filter_by: 'Filtra per',
            active_connection: 'Collegamenti attivi',
            deactivated: 'Disattivati',
            waiting: 'Richieste in attesa di approvazione',
        },
        connectDetail: {
            visibility_type: 'Tipologia di visibilità',
            visibility_option_mutual: 'Reciproca',
            visibility_option_mutual_text:
                'Entrambi potete cercare all’interno dei vostri database',
            visibility_option_internal_share:
                "Hai accesso al database dell'altra azienda",
            visibility_option_internal_share_text:
                'Puoi cercare all’interno del database di {company_name}',
            visibility_option_db_access: 'Concedi accesso al tuo database',
            visibility_option_db_access_text:
                '{company_name} può cercare nel tuo database',
            profile_shared: 'Quali dei tuoi profili stai condividendo?',
            seniority: 'Seniority',
            shared_saved_profiles: 'Quanti dei tuoi profili stai condividendo?',
            percentage_in_database: 'Pari al {percentage}% del tuo database.',
        },
        request_list: {
            request_to_approve: 'richieste da approvare',
            company_name: 'Nome azienda',
            sector: 'Settore',
            visibility_type: 'Tipologia di visibilità',
        },
        visibility_dialog: {
            type: 'Tipologia visibilità',
            content_reciprocal:
                'Stai impostando la visibilità reciproca. {company_name} potrà vedere il tuo database di CV e tu potrai vedere quello di {company_name}.',
            content_active:
                'Stai impostando la visibilità Attiva. Puoi cercare all’interno del database di {company_name}.',
            content_passive:
                'Stai impostando la visibilità Passiva. {company_name} potrà vedere il tuo database di CV.',
            content_none:
                'Stai togliendo la visibilità. {company_name} non potrà vedere il tuo database di CV e tu non potrai vedere quello di {company_name}. ',
            visibility_settings: 'Impostazioni visibilità',
            visibility_text:
                'Concedi la visibilità dell’intero database? Ti ricordiamo che verrà concessa solo la visibilità dei macrodati del tuo database',
        },
        visibility_settings: {
            connection_type: 'Tipologia visibilità richiesta di collegamento',
            mutual: 'Reciproca',
            mutual_explanation: 'Entrambi potete cercare all’interno dei vostri database',
            shared: "Hai accesso al database dell'altra azienda",
            shared_explanation: 'Puoi cercare all’interno del database di {company_name}',
            db_access: 'Concedi accesso al tuo database',
            db_access_explanation: '{company_name} può cercare nel tuo database',
        },
        no_connections: 'Non abbiamo trovato aziende che corrispondono alla tua ricerca',
        accepted: 'Collegamento attivo',
        none: 'Collegati',
        revoked: 'Disattivato',
        to_be_accepted: 'Richiesta in attesa',
        tooltip: {
            active: "Puoi cercare all'interno del database di {company_name}",
            none: 'Nessun collegamento attivo',
            passive: '{company_name} può cercare nel tuo database',
            reciprocal: 'Entrambi potete cercare all’interno dei vostri database',
        },
        visibility: {
            active: 'Attiva',
            none: 'Nessuna',
            passive: 'Passiva',
            reciprocal: 'Reciproca',
        },
        clickInfoMessage: 'clicca qui per gestire le richieste',
        see_details: 'Vedi dettagli',
        active: 'Attiva',
        passive: 'Passiva',
        reciprocal: 'Reciproca',
        refused: 'Rifiutato',
        suspended: 'Sospeso',
        error_accept_relation: 'Errore durante la richiesta di accettazione',
        error_refuse_relation: 'Errore durante la richiesta di rifiuto',
        connect_success: 'Richiesta inviata con successo',
        accept_relation_success: 'Richiesta accettata con successo',
        refuse_relation_success: 'Richiesta rifiutata con successo',
        no_request: 'Non abbiamo trovato nessuna richiesta di collegamento',
    },
    no_company_found:
        'Nessuna azienda corrisponde alla tua ricerca. Potrebbe non esistere oppure avere OpenHr disattivato.',
    open_hr_active_tooltip:
        "Puoi cercare all’interno del database di CV dell'altra azienda",
    open_hr_passive_tooltip: 'Concedi accesso al tuo database di CV',
    open_hr_reciprocal_tooltip:
        'Entrambi potete cercare all’interno dei vostri database di CV',
};

export default it;
