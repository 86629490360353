import { Businessarea, BusinessareaListDocument } from '@/graphql/types/graphql';

import query from '@/graphql/query';
import { useBusinessareasStore } from '@/store/businessareas';
import { watch } from 'vue';

export function useBusinessAreaList(language_id: string) {
    const { result, loading, onError } = query(BusinessareaListDocument, {
        language_id: language_id,
    });
    return { result, loading, onError };
}

export function getBusinessAreas(language_id: string) {
    const store = useBusinessareasStore();
    const { result, loading, onError } = useBusinessAreaList(language_id);
    store.loading = loading.value;

    watch(result, (data) => {
        store.businessareas = data?.BusinessareaList as Businessarea[];
        if (data && data.BusinessareaList) {
            store.filtered = data.BusinessareaList.map(
                (el) => el?.description
            ) as string[];
        }
        store.loading = loading.value;
    });

    onError((result) => {
        console.error(result.message);
        store.loading = loading.value;
    });
}
