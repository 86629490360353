import { Maybe, User } from '../graphql/types/graphql';

import _ from 'lodash';

export function initCustomerly(user: Maybe<User>) {
    if (!_.isUndefined(user)) {
        const customerlyConfig = {
            app_id: 'c8c46e43',
            user_id: user?.id,
            name: user?.name + ' ' + user?.surname,
            email: user?.email,
            attributes: {
                phone_number: user?.phone,
            },
        };
        (window as any)['customerly'].update(customerlyConfig);
    }
}

export function logoutCustomerly() {
    (window as any)['customerly'].logout();
}

export function hideCustomerly() {
    (window as any)['customerly'].hide();
}

export function showCustomerly() {
    (window as any)['customerly'].show();
}
