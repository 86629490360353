const it = {
    labels: 'Etichette',
    my_labels: 'Le mie etichette',
    add_label: 'Aggiungi una nuova etichetta',
    create_label_name_hint: 'Nome etichetta',
    create_label_description_hint: 'Descrizione etichetta',
    confirm_delete_label: "Confermi di voler eliminare l'etichetta <b>{labelName}</b>?",
    search_label: 'Cerca etichetta',
    manage_labels: 'Gestisci etichette',
    parent_label: 'Etichetta genitore',
};
export default it;
